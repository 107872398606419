// javascript/controllers/admin_private_leasing_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {getSearchDataFromStorage, saveSearchDataToStorage} from "../../helpers";

export default class extends Controller {
  static targets = ["downloadCSV"];

  private_leasing = {
    per_page: 10,
    search: "",
    sort: null,
    category_filter: "IN STOCK",
  };

  connect() {
    this.updateInputs();
  }

  create_search_params(params) {
    let valid_params = Object.keys(params).filter((key) => params[key]);
    let query = valid_params.reduce((acc, cur) => {
      return acc + `${cur}=${params[cur]}&`;
    }, "");
    return query;
  }

  getSearchData(event) {
    let element = event.target;
    let value = element.value;
    let param = element.dataset.searchValue;
    if (param === "year") {
      this.private_leasing["sort"] = this.setSortType("year");
    } else if (param === "publish") {
      this.private_leasing["sort"] = this.setSortType("publish");
    } else {
      this.private_leasing[param] = value;
    }
    this.updateData();
  }

  setSortType(type) {
    let current_values = getSearchDataFromStorage("private_leasing");
    let query = `${type} asc`;
    if (current_values) {
      let type_value = current_values["sort"];
      if (type_value) {
        if (type_value === query) {
          query = `${type} desc`;
        }
      }
    }

    return query;
  }

  updateData() {
    let search_params = this.create_search_params(this.private_leasing);
    saveSearchDataToStorage("private_leasing", this.private_leasing);
    Rails.ajax({
      type: "get",
      url: `/admin/private_leasing?${search_params}`,
    });
  }

  updateInputs() {
    let inputs = ["per_page", "search", "category_filter"];
    let current_values = getSearchDataFromStorage("private_leasing");
    if (current_values) {
      this.private_leasing = current_values;
      inputs.forEach((input) => {
        document.getElementById(`private_leasing_${input}`).value =
          current_values[input];
      });
    }
  }

  clearSearchData() {
    this.private_leasing = {
      per_page: 10,
      search: "",
      sort: null,
      category_filter: "IN STOCK",
    };
    this.updateData();
    this.updateInputs();
  }

  selectAllRows(event) {
    let main_checkbox = event.target;
    let is_checked = main_checkbox.checked;
    let checkboxes = document.querySelectorAll(".selectable-checks");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = is_checked;
    });
  }

  singleRowToCSV(event) {
    let value = event.target.value;
    this.downloadCSVTarget.href = `${location.href}.csv?reservation_id=${value}`;
  }

  exportAsCSV(event) {
    event.preventDefault();
    let checkboxes = document.querySelectorAll(".selectable-checks");
    let ids = [];
    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          ids.push(checkbox.value);
        }
      });
    }

    if (ids.length > 0) {
      location.href = `${location.href}.csv?private_leasing_id=${ids}`;
    }
  }
}
