import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
  }

  scrollLeft(e){
    let element = e.target;
    let parent = element.closest(".factory_carousel_container");
    this.startScrolling(parent, "left")
  }

  scrollRight(e){
    let element = e.target;
    let parent = element.closest(".factory_carousel_container");
    this.startScrolling(parent, "right")
  }

  startScrolling(parent, direction){
    let carousel = parent.querySelector(".factory_carousel");
    carousel.style.scrollBehavior = "smooth";
    let carousel_children = carousel.querySelectorAll(".factory_carousel_item");
    let { x: carousel_x, right: carousel_right, width: carousel_width } = carousel.getBoundingClientRect();
    let first_child = carousel_children[0];
    let last_child = carousel_children[carousel_children.length - 1];
    let { x: first_child_x, width: first_child_width } = first_child.getBoundingClientRect();
    let { x: last_child_x } = last_child.getBoundingClientRect();
    let factory_left_indicator = parent.querySelector(".factory_left_indicator");
    let factory_right_indicator = parent.querySelector(".factory_right_indicator");

    let scroll_step = first_child_width - 10;

    let scroll_width = carousel_width / first_child_width
    if(scroll_width > 1 ){
      scroll_step *= scroll_width;
    }

    /*

    if (last_child_x > carousel_right) {
      factory_right_indicator.classList.add("d-none");
    }else {
      factory_right_indicator.classList.remove("d-none");
    }*/

    if(direction === "left") {
      carousel.scrollLeft += scroll_step;
      if (last_child_x < carousel_right) {
        factory_left_indicator.classList.add("d-none");
      }

      if(first_child_x < carousel_x) {
        factory_right_indicator.classList.remove("d-none");
      }
    }

    if(direction === "right") {
      carousel.scrollLeft -= scroll_step;
      if (last_child_x > carousel_right) {
        factory_left_indicator.classList.remove("d-none");
      }

      if(first_child_x >= carousel_x) {
        factory_right_indicator.classList.add("d-none");
      }
    }
  }
}
