// javascript/controllers/search_agent_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {getSearchDataFromUrl, saveSearchDataToStorage} from "../helpers"

export default class extends Controller {

    static targets = [
        "mainContainer",
        "mainForm",
        "submitBtn",
        "submitForm",
        "errorMessage",
    ];

    fields_with_errors = [];
    HOME_PAGE_KEY = "+6Tww,H?zEwaj3%QB^ag)+_";
    CURRENT_PAGE_KEY = "uj/x/uywY34Q&.'?LZxkd&";
    SEARCH_STORAGE_KEY = "@Tm+6x!Bns+SzNk7r+YC4[UX-W";

    connect(){
        //console.log(this)
    }

    submitForm(event) {
        let controller = this;
        event.preventDefault();
        let form_elements = this.mainFormTarget.elements;
        let [first_name, last_name, email, phone, message, consent] = form_elements;

        Rails.ajax({
            url: "/hakuvahdit",
            type: "POST",
            data: new FormData(this.mainFormTarget),
            success: function (response) {
                let { status, data } = response;
                if (status === "SUCCESS") {
                    let container = document.getElementById("search-agent-modal-body");
                    container.innerHTML = `${data}<button type="button" class="btn btn-secondary mt-3" data-bs-dismiss="modal">Sulje</button></div>`;
                }
            },
            error: function (response) {
                let error_fields = Object.keys(response).map(
                  (key) => `search_agent_${key}`
                );
                error_fields.forEach((field_name) => {
                    let ele = document.getElementById(field_name);
                    if (ele) {
                        ele.classList.add("is-invalid");
                    }
                });
                controller.fields_with_errors = error_fields;
                if (error_fields.length > 0) {
                    let first_element = document.getElementById(error_fields[0]);
                    first_element.scrollIntoView();
                }
            },
        });
    }

    removeErrorIndicator(event) {
        let ele_id = event.target.id;
        if (
            this.fields_with_errors.length > 0 &&
            ele_id &&
            this.fields_with_errors.indexOf(ele_id) >= 0
        ) {
            let current_element = document.getElementById(ele_id);
            if (current_element) {
                current_element.classList.remove("is-invalid");
            }
        }
    }

    goToResult(event){
        let element = event.target;
        let loc = element.dataset.agentLocation;
        let href = element.href.split("?");
        let search_data = getSearchDataFromUrl(href[1]);
        saveSearchDataToStorage(this.HOME_PAGE_KEY, "homepage");
        saveSearchDataToStorage(this.CURRENT_PAGE_KEY, "/search_agent");
        saveSearchDataToStorage(this.SEARCH_STORAGE_KEY, search_data);
        location.href = loc;
    }
}
