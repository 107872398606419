// javascript/controllers/payment_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["carPresentationForm", "cancelCarPresentationForm"];
  fields_with_errors = [];

  connect() {
    this.getAvailableDates();
  }

  removeErrorIndicator(event) {
    let ele_id = event.target.id;
    if (
      this.fields_with_errors.length > 0 &&
      ele_id &&
      this.fields_with_errors.indexOf(ele_id) >= 0
    ) {
      let current_element = document.getElementById(ele_id);
      if (current_element) {
        current_element.classList.remove("is-invalid");
      }
    }
  }

  newCarReservation(event) {
    event.preventDefault();
    let data = new FormData(this.carPresentationFormTarget);
    let url = this.carPresentationFormTarget.action;
    Rails.ajax({
      type: "post",
      url: url,
      data: data,
      success: (response) => {
        let { status } = response;
        if (status === "SUCCESS") {
          let container = document.getElementById(
            "car-presentation-modal-body"
          );
          container.innerHTML = `<p>Kiitos. Varauksesi on vastaanotettu. Saat pian vahvistuksen sähköpostiin.</p>
          <div><button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Sulje</button></div>`;
        }
      },
      error: (response) => {
        let error_fields = Object.keys(response).map((key) => {
          return `car_presentation_${key}`;
        });
        error_fields.forEach((field_name) => {
          let ele = document.getElementById(field_name);
          if (ele) {
            ele.classList.add("is-invalid");
          }
        });
        this.fields_with_errors = error_fields;
      },
    });
  }

  getSelectedValues(event) {
    this.getAvailableDates();
  }

  getAvailableDates() {
    let department = document.getElementById("car_presentation_department")
      .value;
    let selected_date = document.getElementById(
      "car_presentation_selected_date"
    ).value;

    Rails.ajax({
      url: `/auton-esittelyt/get_available_time?department=${department}&selected_date=${selected_date}`,
      type: "get",
      success: (response) => {
        let { status, data } = response;
        if (status === "SUCCESS") {
          let select = document.getElementById(
            "car_presentation_selected_time"
          );
          select.innerHTML = "";
          data.forEach((date, index) => {
            var opt = document.createElement("option");
            opt.value = date;
            opt.innerHTML = date;
            select.appendChild(opt);
          });

          if(data.length === 0){
            select.innerHTML += `<option value="">Ei vapaata aikaa tälle päivälle. Muuta esityksen päivämäärä nähdäksesi lisää vaihtoehtoja.</option>`
          }else {
            select.innerHTML += `<option value="">Muuta esityksen päivämäärä nähdäksesi lisää vaihtoehtoja.</option>`;
          }
        }
      },
      error: (response) => {
        console.log({ response });
      },
    });
  }

  cancelCarReservation(event) {
    event.preventDefault();
    let data = new FormData(this.cancelCarPresentationFormTarget);
    let url = this.cancelCarPresentationFormTarget.action;
    let container = document.getElementById(
      "cancel-car-presentation-modal-body"
    );
    Rails.ajax({
      type: "post",
      url: url,
      data: data,
      success: (response) => {
        let { status } = response;
        if (status === "OK") {
          container.innerHTML = `<p>Varauksesi on peruutettu.</p>
          <div><button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Sulje</button></div>`;
          location.reload();
        }
      },
      error: (response) => {
        container.innerHTML = `<p class="text-danger">Virhe. Päivitä sivu ja yritä uudelleen.</p>
          <div><button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Sulje</button></div>`;
      },
    });
  }

  updatePresentationFormat(e){
    let element = e.target;
    let format = element.value;
    this.changeFormatInput(format);
  }

  changeFormatInput(format){
    let presentationDateTime = document.querySelector('.presentation-date-time');
    if(format === "WhatsApp"){
      presentationDateTime.classList.add('d-none');
    }else {
      presentationDateTime.classList.remove('d-none');
    }
  }
}
