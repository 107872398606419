// javascript/controllers/blog_controller.js
import {Controller} from "stimulus";
import {getSearchDataFromStorage, saveSearchDataToStorage,} from "./../helpers";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["showMoreBtn", "tagNameContainer", "totalArticles"];
  FILTER_PROPERTY_NAME_REGULAR = ";9uBB*t?DM)AR8tGYsJvP/'p";
  FILTER_PROPERTY_NAME_COMPANY = "d$R(&B}8eruG)N(pV5PS2Y'Q";
  filter_properties = {
    page: 2,
    tag: "all",
  };
  current_page = 1;
  tag_from_url = null;
  connect() {
    this.tag_from_url = this.getUrlParameters();
    if(this.tag_from_url){
      this.get_blogs("tagged", this.tag_from_url);
    }else {
      this.get_blogs("initial");
    }
  }

  getUrlParameters(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('tag');
  }

  get_blogs(state, tag_name = null) {
    let controller = this;
    let blogs_grid = document.getElementById("blogs-grid");
    let round = blogs_grid.dataset.round;
    let blog_type = blogs_grid.dataset.type;
    let filter_storage_key =
      blog_type === "company"
        ? this.FILTER_PROPERTY_NAME_COMPANY
        : this.FILTER_PROPERTY_NAME_REGULAR;
    let url = "";
    if (state == "initial" && round == "initial") {
      let url_string = "/all_blogs?step=1&items=10";
      blogs_grid.dataset.round = "updated";
      url = blog_type === "company" ? `${url_string}&type=company` : url_string;
      saveSearchDataToStorage(filter_storage_key, controller.filter_properties);
    } else if (tag_name && state == "tagged") {
      //blogs_grid.dataset.round = "tagged";
      controller.showMoreBtnTarget.classList.add("d-none");
      let tag_name_value = `?tag=${tag_name}&type=${blog_type}&items=10`;
      url = `/all_blogs${tag_name_value}`;
      this.filter_properties["tag"] = tag_name;
      saveSearchDataToStorage(filter_storage_key, controller.filter_properties);
    }

    if (url) {
      Rails.ajax({
        url: url,
        type: "GET",
        success: (response) => {
          let total_blog_articles = document.getElementById(
            "total-blog-articles"
          );
          if (blogs_grid.innerHTML) {
                      if (total_blog_articles) {
            let total_articles = total_blog_articles.dataset.totalArticles;
            if (parseInt(total_articles, 10) < 10) {
              controller.showMoreBtnTarget.classList.add("d-none");
            }else {
            controller.showMoreBtnTarget.classList.remove("d-none");
            }
          }

            controller.updateActiveTagName(filter_storage_key);
            blogs_grid.scrollIntoView();

            if (controller.showMoreBtnTarget.classList.contains("disabled")) {
              controller.showMoreBtnTarget.classList.remove("disabled");
            }
          } else {
            blogs_grid.innerHTML =
              "<h1 class='fs-3'>Ei artikkeleita</h1>";
          }
        },
      });
    }
  }

  updateList() {
    let controller = this;
    let blogs_grid = document.getElementById("blogs-grid");
    let blog_type = blogs_grid.dataset.type;
    let filter_storage_key =
      blog_type === "company"
        ? this.FILTER_PROPERTY_NAME_COMPANY
        : this.FILTER_PROPERTY_NAME_REGULAR;
    let storage_data = getSearchDataFromStorage(filter_storage_key);
    let page_number = storage_data["page"];
    let tag_name = storage_data["tag"];
    let total_articles = this.totalArticlesTarget.dataset.totalArticles;
    if (page_number) {
      Rails.ajax({
        url: `/all_blogs?page=${page_number}&tag=${tag_name}&type=${blog_type}`,
        type: "GET",
        dataType: "json",
        success: function (response) {
          let { data, pagy } = response;
          let { page, next, last } = pagy;
          if (page_number <= page && next <= last) {
            storage_data["page"] = next;
            saveSearchDataToStorage(filter_storage_key, storage_data);
            let container = document.getElementById("blogs-grid");
            if (container) {
              container.insertAdjacentHTML("beforeend", data);
            }

            if (next === last || page < 2) {
              controller.showMoreBtnTarget.classList.add("d-none");
            }
          } else {
            controller.showMoreBtnTarget.classList.add("d-none");
          }
        },
      });
    } else {
      controller.showMoreBtnTarget.classList.add("disabled");
    }
  }

  getByTagName(event) {
    let value = event.target.textContent;
    let tag_name_value = "";
    if (value) {
      tag_name_value = value === "Kaikki" ? "all" : value;
    } else {
      tag_name_value = "all";
    }
    this.get_blogs("tagged", tag_name_value);
  }

  updateActiveTagName(filter_storage_key) {
    let storage_date = getSearchDataFromStorage(filter_storage_key);
    let tag = storage_date["tag"];
    let tag_name = tag === "all" ? "Kaikki" : tag;
    let container = this.tagNameContainerTarget;
    if (container) {
      let buttons = container.getElementsByTagName("button");
      Array.from(buttons).forEach((btn) => {
        if (btn.textContent.toLowerCase() === tag_name.toLowerCase()) {
          if (btn.classList.contains("text-gray67")) {
            btn.classList.remove("text-gray67");
            btn.classList.remove("border-gray67");
            btn.classList.remove("border-1");
            btn.classList.add("text-dark");
            btn.classList.add("border-dark");
            btn.classList.add("border-2");
          }
        } else {
          if (btn.classList.contains("text-dark")) {
            btn.classList.remove("text-dark");
            btn.classList.remove("border-dark");
            btn.classList.remove("border-2");
            btn.classList.add("text-gray67");
            btn.classList.add("border-gray67");
            btn.classList.add("border-1");
          }
        }
      });
    }
  }
}
