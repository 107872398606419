// javascript/controllers/pdf_downloader_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
    static targets = [];
    connect() {
        this.startDownload();
    }

    startDownload() {
        let admin = location.pathname.split("/").indexOf('admin') >= 0;
        if(!admin){
            let files = document.querySelectorAll('.file');
            let links = Array.from(files).map(link => link)
            if(links.length > 0){
                links.forEach(u_link => {
                    u_link.click();
                });
            }
        }
    }
}
