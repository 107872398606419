// javascript/controllers/contact_request_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "mainContainer",
    "mainForm",
    "submitBtn",
    "submitForm",
    "errorMessage",
  ];


  contact_has_sell_car = false;
  car_for_money = {
    reg_num: null,
    km: null,
    service_category: "AUTO_RAHAKSI"
  }

  submitForm(event) {
    let controller = this;
    event.preventDefault();
    let form_elements = this.mainFormTarget.elements;
    let title = sessionStorage.getItem('contact_card_title')

    let contact_form_data = new FormData(this.mainFormTarget);
    // if title present append it location element in the form
    if(title){
      let location = contact_form_data.get('location')
      contact_form_data.set('location', `${title} - ${location}`)
    }

    if(controller.car_for_money.reg_num && controller.car_for_money.km){
      contact_form_data.append('reg_num', this.car_for_money.reg_num)
      contact_form_data.append('km', this.car_for_money.km)
    }

    Rails.ajax({
      url: "/contact_requests",
      type: "POST",
      data: contact_form_data,
      success: function (response) {
        let { status } = response;
        if (status === "error") {
          controller.errorMessageTarget.innerHTML = `<li>${response.message}</li>`;
        } else if (status === "created") {
          controller.mainContainerTarget.innerHTML = response.data;
          let car_for_money_reg_num = document.getElementById('car_for_money_reg_num');
          let car_for_money_km = document.getElementById('car_for_money_km');
          if(car_for_money_reg_num){
            car_for_money_reg_num.value = ""
          }
          if(car_for_money_km){
            car_for_money_km.value = ""
          }
          sessionStorage.removeItem('contact_card_title')
        }
      },
      error: function (response) {
        let errors = "";
        Object.keys(response).forEach((key) => {
          let value = response[key];
          errors += `<li>${value.join(". ")}</li>`;
        });
        controller.errorMessageTarget.innerHTML = errors;
      },
    });
  }

  submitFormWithSellCarInfo(event){
    event.preventDefault();
    let reg_num_input = document.getElementById('car_contact_reg_num');
    let km_input = document.getElementById('car_contact_km');

    if(this.contact_has_sell_car && !reg_num_input.value && !km_input.value){
      reg_num_input.classList.add('is-invalid');
      km_input.classList.add('is-invalid');
      return;
    }

    let main_container = document.getElementById("contact_request_car_page")
    let form = document.getElementById("contact_request_car_page_form")
    let errorMessage = document.getElementById("contact_sellcar_error_message")

    Rails.ajax({
      url: "/contact_requests",
      type: "POST",
      data: new FormData(form),
      success: function (response) {
        let { status } = response;
        if (status === "error") {
          errorMessage.innerHTML = `<li>${response.message}</li>`;
        } else if (status === "created") {
          main_container.innerHTML = response.data;
        }
      },
      error: function (response) {
        let errors = "";
        Object.keys(response).forEach((key) => {
          let value = response[key];
          errors += `<li>${value.join(". ")}</li>`;
        });
        errorMessage.innerHTML = errors;
      },
    });
  }

  submitInpectionRequestForm(event){
    event.preventDefault();
    let form = document.getElementById('inspection_request_form');
    let form_container = document.getElementById('inspection_request_car_page');
    let error_messages = document.getElementById('inspection_error_message');
    let email_input = document.getElementById('car_inspection_email');
    this.inspectionDataRequest(form, form_container, error_messages, email_input);
  }

  submitCosmeticRequestForm(event){
    event.preventDefault();
    let form = document.getElementById('cosmetic_request_form');
    let form_container = document.getElementById('cosmetic_request_car_page');
    let error_messages = document.getElementById('cosmetic_error_message');
    let email_input = document.getElementById('car_cosmetic_email');
    this.inspectionDataRequest(form, form_container, error_messages, email_input);
  }

  inspectionDataRequest(form, form_container, error_messages, email_input){
    if(!email_input || !email_input.value){
      email_input.classList.add('is-invalid');
      return;
    }
    let submit_btn = document.getElementById('inspection_submit-btn')
    submit_btn.disabled = true

    Rails.ajax({
      url: "/contact_requests",
      type: "POST",
      data: new FormData(form),
      success: function (response) {
        let { status } = response;
        if (status === "error") {
          submit_btn.disabled = false;
          error_messages.innerHTML = `<li>${response.message}</li>`;
        } else if (status === "created") {
          form_container.innerHTML = response.data;
        }
      },
      error: function (response) {
        let errors = "";
        Object.keys(response).forEach((key) => {
          let value = response[key];
          errors += `<li>${value.join(". ")}</li>`;
        });
        error_messages.innerHTML = errors;
        submit_btn.disabled = false;
      },
    });
  }

  hasSellCar(event) {
    let element = event.target;
    let info_container = document.getElementById('contact_sellcar_info')
    if(element && element.checked){
      this.contact_has_sell_car = true;
      info_container.classList.remove('d-none');
    }else {
      this.contact_has_sell_car = false;
      info_container.classList.add('d-none');
    }
  }

  removeError(event){
    let element = event.target;
    if(element){
      if(element.classList.contains('is-invalid')){
        element.classList.remove('is-invalid')
      }
    }
  }

  updateContactField(event){
    let element = event.target;
    let input_name = element.name
    this.car_for_money[input_name] = element.value;
    //let submit_btn = document.getElementById('car-for-money-submit-btn');
    let parent = element.parentElement.parentElement.parentElement
    let submit_btn = parent.querySelector("#car-for-money-submit-btn")
    if(this.car_for_money.reg_num && this.car_for_money.km){
      let contact_reg_num = parent.querySelector('#contact_reg_num');
      let contact_km = parent.querySelector('#contact_km');
      if(contact_reg_num && contact_km){
        contact_reg_num.value = this.car_for_money.reg_num;
        contact_km.value = this.car_for_money.km;
      }

      if(submit_btn.classList.contains('disabled')){
        submit_btn.classList.remove('disabled')
        submit_btn.classList.remove('btn-light')
        submit_btn.classList.remove('text-dark')
        submit_btn.classList.add('btn-primary')
        submit_btn.classList.add('text-white')
      }
    }else {
      if(submit_btn.classList.contains('btn-primary')) {
        submit_btn.classList.add('disabled')
        submit_btn.classList.add('btn-light')
        submit_btn.classList.add('text-dark')
        submit_btn.classList.remove('btn-primary')
        submit_btn.classList.remove('text-white')
      }
    }
  }

  updateCarForMoneyDetails(event){
    let element = event.target;
    setTimeout(() => {
      let parent = element.parentElement.parentElement
      let sell_request_modal = document.getElementById('sellRequestFormModal');
      let display = sell_request_modal.style.display
      if(display === 'block'){
        let contact_reg_num = parent.querySelector('#car_for_money_reg_num');
        let contact_km = parent.querySelector('#car_for_money_km');
        let form = sell_request_modal.getElementsByTagName('form')[0]
        if(form){
          let inputs = form.getElementsByTagName('input')
          Array.from(inputs).forEach(input => {
            if(input.name === 'reg_num'){
              input.value = contact_reg_num.value;
              contact_reg_num.value = "";
            }else if(input.name === 'km'){
              input.value = contact_km.value;
              contact_km.value = "";
            }else if(input.name === 'service_category'){
              input.value = this.car_for_money.service_category;
            }
          })
        }
      }
    }, 1000)
  }

  submitVipForm(e){
    e.preventDefault();
    let controller = this;
    let form = e.target.closest('form');
    let invalidElements = []
    Array.from(form.elements).forEach(element => {
      if(element.type !== 'submit' && !element.value){
        element.classList.add('is-invalid')
        invalidElements.push(element.name)
      }
    });

    if(invalidElements.length === 0){
      let contact_form_data = new FormData(form);

      Rails.ajax({
        url: "/contact_requests",
        type: "POST",
        data: contact_form_data,
        success: function (response) {
          let message = "<p class='text-success fs-12 text-center mt-3'>Kiitos. Tietosi tallennettu onnistuneesti.</p>"
          form.insertAdjacentHTML("afterend", message);
          Array.from(form.elements).forEach(element => {
            if(element.type === 'text' || element.type === 'email'){
              element.value = "";
            }
          });
        },
        error: function (response) {
          let message = "<p class='text-danger fs-12 text-center mt-3'>Virhe lomakkeen lähettämisessä. Yritä uudelleen myöhemmin.</p>"
          form.insertAdjacentHTML("afterend", message);
          Array.from(form.elements).forEach(element => {
            if(element.type === 'text' || element.type === 'email'){
              element.value = "";
            }
          });
        },
      });
    }
  }

  validateField(e){
    e.target.classList.remove('is-invalid')
  }

  showTooltip(e){
    let element = e.target;
    let container = element.closest('.form-check');
    let tooltip = container.querySelector('.simple-tooltip');
    if(tooltip){
      tooltip.classList.toggle('d-none');
    }
  }

  sendImportCarContact(e){
    e.preventDefault();
    let target = e.target;
    let form = target.closest('form');
    let invalidElements = []
    let inputs = form.getElementsByTagName('input');
    let textarea = form.getElementsByTagName('textarea')[0];
    // combine all inputs and textarea
    let allInputs = [...inputs, textarea];
allInputs.forEach(input => {
      if(input.type === 'text' && !input.value || input.type === 'email' && !input.value || input.type === 'checkbox' && !input.checked){
        input.classList.add('is-invalid')
        invalidElements.push(input.name)
      }else if(input.type === 'textarea' && !input.value){
        input.classList.add('is-invalid')
        invalidElements.push(input.name)
      }
    });

    if(invalidElements.length > 0){
      return;
    }

    let contact_form_data = new FormData(form);
    let container = document.getElementById("car-page-container");
    let page = container.dataset.page;
    if(page) {
      contact_form_data.set('location', page);
    }
    Rails.ajax({
      url: "/tuontiautot/contact",
      type: "POST",
      data: contact_form_data,
      success: function (response) {
        let { status, message } = response;
        if(status === "SUCCESS"){
          let form_container = document.getElementById('import_car_contact_request');
          form_container.innerHTML = `<div>
          <p class='text-success mt-3'>${message}</p>
          <button class="btn btn-primary text-white outline-none no-shadow-btn" data-bs-dismiss="modal" aria-label="Close">Sulje</button>
        </div>`;
        }else {
            let message_tag = `<p class='text-danger fs-12 text-center mt-3'>${message}</p>`
            form.insertAdjacentHTML("afterend", message_tag);
        }
      },
      error: function (response) {
        let message = "<p class='text-danger fs-12 text-center mt-3'>Virhe lomakkeen lähettämisessä. Yritä uudelleen myöhemmin.</p>"
        form.insertAdjacentHTML("afterend", message);
      },
    });
  }
}
