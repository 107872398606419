// javascript/controllers/admin_sell_car_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["downloadCSV"];

  selectedNumberOfItems(event) {
    let element = event.target;
    let value = element.value;
    //element.dataset.params = `per_page=${value}`;
    this.updateData("per_page", value);
  }

  searchSellCars(event) {
    let element = event.target;
    let value = element.value;
    //element.dataset.params = `search=${value}`;
    this.updateData("search", value);
  }

  updateData(key, value) {
    Rails.ajax({
      type: "get",
      url: `/admin/sell_cars?${key}=${value}`,
    });
  }

  selectAllRows(event) {
    let main_checkbox = event.target;
    let is_checked = main_checkbox.checked;
    let checkboxes = document.querySelectorAll(".selectable-checks");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = is_checked;
    });
  }

  singleRowToCSV(event) {
    let value = event.target.value;
    this.downloadCSVTarget.href = `${location.href}.csv?sell_car_id=${value}`;
  }

  exportAsCSV(event) {
    event.preventDefault();
    let checkboxes = document.querySelectorAll(".selectable-checks");
    let ids = [];
    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          ids.push(checkbox.value);
        }
      });
    }

    if (ids.length > 0) {
      location.href = `${location.href}.csv?sell_car_id=${ids}`;
    }
  }
}
