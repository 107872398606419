// javascript/controllers/video_carousel_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["carouselContainer", "carouselMain"];

    current_card = 1;
    first_child_width = 528;
    unit = "px"
    total_items_length = 0;
    connect() {
        this.cloneFirstAndLastCards();
    }

    cloneFirstAndLastCards(){
        let container = this.carouselContainerTarget; // document.getElementById('video-carousel-container');
        if(window.innerWidth <= 540){
            this.unit = "vw";
            this.first_child_width = 98;
        }
        this.total_items_length = container.children.length
        const first_card_clone = container.children[0].cloneNode(true)
        const last_card_clone = container.children[container.children.length - 1].cloneNode(true)
        container.insertBefore(last_card_clone, container.children[0]);
        container.appendChild(first_card_clone);
        container.style.transitionDuration = "0.0s";
        container.style.transform = `translate(-${this.first_child_width}${this.unit})`;
        this.updateCardSize();
        this.createDots();
    }

    createDots(){
        let index = 0;
        let indicators = document.querySelector(".video-carousel__indicators")
        let html = "";
        while (index < this.total_items_length){
            let bg = index === (this.current_card - 1)
            html += `<div class="video-carousel__indicators-dots ${bg ? 'video-carousel__indicators-active' : ''} mx-1 cursor-pointer"></div>`
            index++;
        }
        indicators.innerHTML = html;
    }

    updateCardSize(){
        let container = this.carouselContainerTarget;
        if(this.unit === 'px'){
            Array.from(container.children).forEach((child, index) => {
                if(index !== this.current_card){
                    child.style.height = "75%";
                }else {
                    child.style.height = "100%";
                }
            })
        }
    }

    previous(){
        let container = this.carouselContainerTarget; //document.getElementById('video-carousel-container');
        if(this.current_card > 0){
            this.current_card--;
            container.style.transitionDuration = "0.8s";
            container.style.transform = `translate(-${this.current_card * this.first_child_width}${this.unit})`;
            this.updateCardSize();
            this.createDots();

            if(this.current_card === 0){
                setTimeout(() => {
                    container.style.transitionDuration = "0.0s";
                    container.style.transform = `translate(-${(container.children.length - 2) * this.first_child_width}${this.unit})`;
                    this.current_card = container.children.length - 2;
                    this.updateCardSize();
                }, 600);
            }
        }
    }

    next(){
        let container = this.carouselContainerTarget; // document.getElementById('video-carousel-container');
        if(this.current_card < container.children.length){
            this.current_card++;
            container.style.transitionDuration = "0.8s";
            container.style.transform = `translate(-${this.current_card * this.first_child_width}${this.unit})`;
            this.updateCardSize();
            this.createDots();

            if(this.current_card === container.children.length - 1){
                setTimeout(() => {
                    container.style.transitionDuration = "0.0s";
                    container.style.transform = `translate(-${this.first_child_width}${this.unit})`;
                    this.current_card = 1;
                    this.updateCardSize();
                }, 600);
            }
        }
    }
}
