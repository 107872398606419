// javascript/controllers/fixed_card_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
    TIRE_SERVICE = 39
    selected_km = null
    car_is_on_top = false
    leaseOptions = {
        "km": 10,
        "kk": 12,
    }
    easyId = null
    tire_service_selected = false
    leasing_km_options = {}
    mainActiveMonth = 12
    mainActiveKm = 10

    connect(){
        let controller = this;
        this.setLeasingKmOptions();
        let car_main = document.getElementById('single-car-main');
        let fixed_card = document.getElementById('fixed-left-card');
        if(fixed_card && window.innerWidth > 900){
            let fixed_card_content = document.getElementById('fixed-left-card-content');
            let accordion = document.getElementById('car-page-accordion-row');

            window.addEventListener('scroll', function (){
                let {top: main_container_top} = car_main.getBoundingClientRect()
                if(main_container_top < 59 && main_container_top > 55 && controller.car_is_on_top){
                    controller.car_is_on_top = false
                }
                let {left: main_left, right: main_right, x: main_x, y: main_y, width: main_width, height: main_height } = fixed_card.getBoundingClientRect();
                if(fixed_card_content && !fixed_card_content.classList.contains('position-fixed') && !controller.car_is_on_top){
                    fixed_card_content.classList.add('position-fixed')
                    fixed_card_content.classList.add('top-75')
                    fixed_card_content.style.left = `${main_left}px`
                    fixed_card_content.style.width = `${fixed_card.offsetWidth}px`
                }
                if (accordion && fixed_card_content) {
                    let {bottom} = fixed_card_content.getBoundingClientRect();
                    let {y} = accordion.getBoundingClientRect();
                    if (bottom - 80 > y) {
                        if (fixed_card_content.classList.contains('position-fixed')) {
                            fixed_card_content.classList.remove('position-fixed')
                            controller.car_is_on_top = true
                        }
                    }
                }
            }, {passive: true})

            window.addEventListener('resize', function(){
                let {left: main_left, right: main_right, x: main_x, y: main_y, width: main_width, height: main_height } = fixed_card.getBoundingClientRect();
                if(fixed_card_content){
                    fixed_card_content.style.left = `${main_left}px`
                    fixed_card_content.style.width = `${fixed_card.offsetWidth}px`
                }
            })
        }

        // Resetting lease data
        sessionStorage.setItem('leaseOptions', JSON.stringify({}));
        this.updateActiveData();
        let km_info = JSON.parse(car_main.dataset.kmInfo);
        if(km_info) {
            this.leasing_km_options = {...this.leasing_km_options, ...km_info}
        }
    }

    setLeasingKmOptions(){
        for(let i = 1; i < 76; i++){
            this.leasing_km_options[`${i}`] = {
                "15": 20,
                "20": 60,
                "25": 110,
                "30": 185,
            }
        }
    }

    updateActiveData(){
        let months_buttons = document.querySelectorAll('.accepted_months_btn')
        let km_buttons = document.querySelectorAll('.accepted_km_btn')
        months_buttons.forEach(btn => {
            if(btn.classList.contains('bg-primary')){
                this.mainActiveMonth = parseInt(btn.textContent, 10);
            }
        })
        km_buttons.forEach(btn => {
            if(btn.classList.contains('bg-primary')){
                this.mainActiveKm = parseInt(btn.textContent, 10);
            }
        })
    }

    updateMonths(e){
        let element = e.target
        let current_text = element.textContent
        let price = element.dataset.price
        let months_buttons = document.querySelectorAll('.accepted_months_btn')
        this.leaseOptions['kk'] = parseInt(current_text, 10);
        this.mainActiveMonth = parseInt(current_text, 10);
        let parse_km_price = this.leasing_km_options[this.mainActiveMonth][this.mainActiveKm]
        if(parse_km_price){
            this.selected_km = parseInt(parse_km_price, 10);
        }else {
            this.selected_km = 0;
        }
        months_buttons.forEach(btn => {
            if(btn.textContent === current_text){
                btn.classList.remove('bg-gray69')
                btn.classList.add('bg-primary')
                let selected_months = document.querySelectorAll('.selected_months');
                selected_months.forEach(month => {
                    month.textContent = current_text
                })
                let calculated_total_price = document.querySelectorAll('.calculated_total_price');
                let calculated_price = this.calculatePriceWithExtraService(price)
                calculated_total_price.forEach(current_price => {
                    current_price.dataset.currentPrice = price
                    current_price.textContent = calculated_price;
                })
            }else {
                btn.classList.remove('bg-primary')
                btn.classList.add('bg-gray69')
            }
        })
    }

    updateExtraKms(e){
        let element = e.target
        let current_text = element.textContent
        let parsed_current_text = parseInt(current_text, 10);
        this.mainActiveKm = parsed_current_text;
        let km_field = document.querySelectorAll('.selected_km')
        km_field.forEach(field => {
            field.textContent = parsed_current_text * 1000
        })
        let current_price = document.querySelector('.calculated_total_price')
        let current_fixed_price = current_price.dataset.currentPrice
        let extra_km_price = this.leasingExtraKms(current_text)
        this.selected_km = extra_km_price
        this.leaseOptions['km'] = parsed_current_text;
        let calculated_price = this.calculatePriceWithExtraService(current_fixed_price)
        current_price.textContent = calculated_price;
        this.updateCalculatedPriceElement(calculated_price)
        let km_buttons = document.querySelectorAll('.accepted_km_btn')
        km_buttons.forEach(btn => {
            if(btn.textContent === current_text){
                btn.classList.remove('bg-gray69')
                btn.classList.add('bg-primary')
            }else {
                btn.classList.remove('bg-primary')
                btn.classList.add('bg-gray69')
            }
        })
    }

    updateExtraService(e){
        let current_price = document.querySelector('.calculated_total_price')
        let price = current_price.textContent
        let calculated_price = parseInt(price, 10)
        this.getExtraServicePrice(e.target.checked)
        if(e.target.checked){
            calculated_price += this.TIRE_SERVICE
            this.leaseOptions['tire_service'] = this.TIRE_SERVICE;
        }else {
            calculated_price -= this.TIRE_SERVICE
            this.leaseOptions['tire_service'] = 0;
        }
        current_price.textContent = calculated_price;
        this.updateCalculatedPriceElement(calculated_price)
        this.leaseOptions['price'] = calculated_price;
        window.sessionStorage.setItem('leaseOptions', JSON.stringify(this.leaseOptions));
    }

    calculatePriceWithExtraService(price){
        let calculated_price = parseInt(price, 10)
        if(this.selected_km){
            calculated_price += this.selected_km
        }
        if(this.tire_service_selected){
            calculated_price += this.TIRE_SERVICE
        }

        this.leaseOptions['price'] = calculated_price;
        window.sessionStorage.setItem('leaseOptions', JSON.stringify(this.leaseOptions));

        return calculated_price;
    }

    getExtraServicePrice(isChecked){
        let checkboxes = document.querySelectorAll('.extra_service_tyres')
        checkboxes.forEach(checkbox => {
            checkbox.checked = isChecked
        })
        this.tire_service_selected = isChecked
    }

    leasingExtraKms(value) {
        let base_values = {
            "15": 20,
            "20": 60,
            "25": 110,
            "30": 185,
            "35": 255,
        }
        let parsed_value = parseInt(value, 10);
        let currentVal = null;
        if(parsed_value > 10){
            let active = parseInt(this.leasing_km_options[this.mainActiveMonth][this.mainActiveKm], 10);
            if(active >= 0 && !isNaN(active)){
                currentVal = active
            }else {
                currentVal = base_values[value]
            }

        }

        switch (value) {
            case "10":
                return 0;
            case "15":
                return currentVal;
            case "20":
                return currentVal;
            case "25":
                return currentVal;
            case "30":
                return currentVal;
            case "35":
                return currentVal;
            default:
                return 0;
        }

        return value;
    }

    updateCalculatedPriceElement(price){
        let calculated_price = document.querySelectorAll('.calculated_total_price');
        calculated_price.forEach(current_price => {
            let previous_price = parseInt(current_price.textContent, 10)
            if(previous_price !== price){
                current_price.textContent = price;
            }
        })
    }
}
