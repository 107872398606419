// javascript/controllers/clipboard_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["clipboardLink", "clipboardSuccess", "clipboardError"];

  copyText() {
    let copyTarget = document.getElementById("clipboard-link");
    copyTarget.setAttribute("type", "text");
    copyTarget.select();

    try {
      document.execCommand("copy");
      document.getElementById("clipboard-success").innerHTML =
        "Sivun osoite kopioitu";
    } catch (err) {
      document.getElementById("clipboard-error").innerHTML =
        "Sivun osoite kopioitu";
    }

    setTimeout(() => {
      document.getElementById("clipboard-success").innerHTML = "";
      document.getElementById("clipboard-error").innerHTML = "";
    }, 1000);
  }
}
