// javascript/controllers/import_cars_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {concatName, replaceClassName} from "../helpers";

export default class extends Controller {
  search_box = null;
  import_filters = null;
  import_category_data = {};

  connect() {
    this.search_box = document.getElementById("import__search-box");
    this.import_filters = document.getElementById("import__filters");
    this.getImportFiltersData();
    this.getSearchCategories();
    setTimeout(() => {
      this.getActiveUrlFilters();
    }, 1000);
    this.getVehiclesList();
    sessionStorage.setItem("current_import_page", window.location.href);
  }

  getVehiclesList() {
    let controller = this;
    let list = document.getElementById("import-cars-list");
    const urlString = window.location.href;
    let queryParamsString = urlString.split("?")
    queryParamsString = queryParamsString.length > 1 ? queryParamsString[1] : "";
    Rails.ajax({
      url: "/tuontiautot/get_vehicles?" + queryParamsString,
      type: "GET",
      success: (response) => {
        const { status, data, total_vehicles } = response;
        list.innerHTML = data;
        // show total vehicles as 1 000 ajoneuvoa
        let total = total_vehicles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        document.getElementById("total-vehicles").textContent = `${total} ajoneuvoa`;
        controller.replacePaginationLinks();
      },
      error: (error) => {
        console.log("Error: ", error);
      }
    });
  }

  getImportFiltersData() {
    let controller = this;
    let list = document.querySelector(".import__search-result-list");
    Rails.ajax({
      url: "/tuontiautot/input_filters",
      type: "GET",
      success: (response) => {
        const { status, data } = response;
        if (status === "SUCCESS") {
          list.innerHTML = data;
        }
      },
      error: (error) => {
          controller.noResultItem();
        }
      });
  }

  getSearchCategories() {
    let controller = this;
    let list = document.getElementById("import__search-category-list");
    Rails.ajax({
      url: "/tuontiautot/search_filters",
      type: "GET",
      success: (response) => {
        const { status, data } = response;
        if (status === "SUCCESS") {
          list.innerHTML = data;
        }
      },
      error: (response) => {
        const { status, data } = response;
        if (status === "ERROR") {
          list.innerHTML = data;
        }
      }
    });
  }

  noResultItem() {
    return `<li class="w-100 d-flex align-items-center justify-content-between px-4 py-1 my-1 d-none"
            data-hint-make="" data-hint-model="" id="no-result-item">
          <p class="l-spacing m-0 d-inline-block text-gray22">Ei tuloksia</p>
        </li>`
  }

  toggleExactSearch() {
    this.toggleElementDisplay(this.import_filters);
  }

  toggleElementDisplay(element) {
    element.classList.remove("invisible");
    if (element.classList.contains("d-none")) {
      element.classList.remove("d-none");
      element.classList.add("d-block");
    } else if (element.classList.contains("d-block")) {
      element.classList.remove("d-block");
      element.classList.add("d-none");
    }
  }

  // Handles user inputs from the main search input
  searchHintInput() {
    let input = document.querySelector(".import-search-input");
    let result_list = document.querySelector(".import__search-box__result");
    let clear_values_btn = document.querySelector(".import-clear-search");

    if (input.value) {
      result_list.classList.remove("d-none");
      result_list.classList.add("d-block");
      clear_values_btn.classList.remove("d-none");
      clear_values_btn.classList.add("d-block");
      this.showHintSearchResult(input.value);
    } else {
      result_list.classList.remove("d-block");
      result_list.classList.add("d-none");
      clear_values_btn.classList.remove("d-block");
      clear_values_btn.classList.add("d-none");
    }
  }

  // Shows ready filter container
  searchHintFocus() {
    let main = document.getElementsByTagName("main")[0];
    if (main) {
      let layout_name = main.dataset.layoutName;
      if (layout_name === "buy") {
        this.updateHomeNavigationTheme("dark");
        let container = document.getElementById("frontpage-contact-container");
        if (container) {
          container.classList.add("d-none");
        }
      }
    }
  }

  clearSearchInput() {
    let input = document.querySelector(".import-search-input");
    let result_list = document.querySelector(".import__search-box__result");
    let clear_values_btn = document.querySelector(".import-clear-search");
    input.value = "";
    this.toggleElementDisplay(result_list);
    this.toggleElementDisplay(clear_values_btn);
  }

  refreshPage() {
    window.location.reload();
  }

  updateSearchFromHintList(event) {
    let list_item = event.target;
    let key = ""
    let list_item_make = list_item.dataset.searchMake;
    let list_item_model = list_item.dataset.searchModel;
    // add make=make&model=model to the current url and remove all existing search params
    let url = new URL(window.location.href);
    url.search = "";
    if(list_item_make && list_item_model){
        url.searchParams.set("make", list_item_make);
        url.searchParams.set("model", list_item_model);
        key = `${list_item_make} ${list_item_model}`;
    }else if(list_item_make && !list_item_model) {
      url.searchParams.set("make", list_item_make);
      key = list_item_make;
    }
    let key_value = this.findSessionFilters(this.stringToSlug(key));
    url.searchParams.set("key", key_value);
    window.location.href = url;
  }

  showHintSearchResult(value) {
    let list = document.querySelector(".import__search-result-list")
    let list_items = list.getElementsByTagName("li");
    let no_search_item = document.getElementById("no-result-item");
    let pattern = new RegExp("^" + value, "i");
    let available_items = [];
    Array.from(list_items).forEach((item, index) => {
      let item_make = item.dataset.hintMake;
      let item_model = item.dataset.hintModel;
      let item_make_model = `${item_make} ${item_model}`;
      if (
        item_make && pattern.test(item_make.toLowerCase()) ||
        item_model && pattern.test(item_model.toLowerCase()) ||
        item_make_model && pattern.test(item_make_model.toLowerCase())
      ) {
        if (item.classList.contains("d-none")) {
          item.classList.remove("d-none");
        }
        available_items.push(index);
      } else {
        if (item.classList.contains("d-block")) {
          item.classList.remove("d-block");
        }
        item.classList.add("d-none");
      }
    });

    if (available_items.length <= 0) {
      no_search_item.classList.remove("d-none");
    }
  }

  clearFilter(event) {
    // reload page and clear all url params
    event.preventDefault();
    let url = new URL(window.location.href);
    url.search = "";
    window.location.href = url;
  }

  // Filters Car make and model that match user's input value
  makeSearchInput(event) {
    let target = event.target;
    let value = target.value;
    if(value.length > 0) {
      let list = document.getElementById("make_search_result");
      let list_items = list.getElementsByTagName("li");
      Array.from(list_items).forEach((item) => {
        let item_make = item.dataset.make;
        let item_model = item.dataset.model;
        if(item_make || item_model){
          if (item_make.toLowerCase().includes(value.toLowerCase()) || item_model.toLowerCase().includes(value.toLowerCase())) {
            item.classList.remove("d-none");
          } else {
            item.classList.add("d-none");
          }
        }
      });
    }
  }

  toggleFilterAction(event) {
    let mainList = this.import_filters.querySelector(".main-search__filters--list");
    let mainList_btns = mainList.querySelectorAll("button");
    let current_filter_id = event.target.dataset.filterId;
    let sublist_parent = document.getElementById(current_filter_id);
    let back_action = event.target.dataset.back;
    let toggle_action = back_action === "back" ? "hide" : "show";
    this.toggleSublist(mainList, sublist_parent, toggle_action);
  }

  toggleSublist(mainList, subList, action) {
    if (action === "show") {
      if (mainList && subList) {
        mainList.classList.add("d-none");
        subList.classList.remove("start-100");
        subList.classList.remove("d-none");
        subList.classList.remove("invisible");
        subList.classList.add("d-block");
        subList.classList.add("start-0");
        subList.classList.add("transition-2s");
      }
    }

    if (action === "hide") {
      if (mainList && subList) {
        mainList.classList.remove("d-none");
        subList.classList.add("start-100");
        subList.classList.add("d-none");
        subList.classList.add("invisible");
        subList.classList.remove("d-block");
        subList.classList.remove("start-0");
        subList.classList.add("transition-2s");
      }
    }
  }

  sharedSearchInput(event) {
    let target_type = event.target.dataset.filterName;
    let value = event.target.value;
    this.toggleVisibilityOfInputs(target_type, value);
  }

  toggleVisibilityOfInputs(name, value) {
    let list = document.getElementById(`${name}_search_result`);
    let list_items = list.getElementsByTagName("li");
    let pattern = new RegExp("^" + value, "i");
    Array.from(list_items).forEach((item) => {
      let item_value = item.dataset.typeName.toLowerCase();
      if (pattern.test(item_value)) {
        if (item.classList.contains("d-none")) {
          item.classList.remove("d-none");
        }
        item.classList.add("d-block");
      } else {
        if (item.classList.contains("d-block")) {
          item.classList.remove("block");
        }
        item.classList.add("d-none");
      }
    });
  }

  setFilterSearchParams(name, value, checked){
    if(checked && !this.import_category_data[name]){
      this.import_category_data[name] = [value];
    }else if(checked && this.import_category_data[name] && value){
      this.import_category_data[name].push(value);
    }else {
        this.import_category_data[name].splice(this.import_category_data[name].indexOf(value), 1);
    }
  }

  selectedMake(event) {
    let current_input = event.target;
    let current_input_name = event.target.dataset.makeName;
    let checked = current_input.checked;
    let value = current_input.value;
    this.setFilterSearchParams("make", value, checked);
    if(!checked){
      // get all input where data-make-model-name is equal to value and uncheck them
        let model_inputs = document.querySelectorAll(`input[data-make-model-name="${value}"]`);
        if(model_inputs){
          model_inputs.forEach((input) => {
            input.checked = false;
            this.setFilterSearchParams("model", input.value, false);
          });
        }
    }
    this.hideShowSelectedMakeCheckboxes(current_input, current_input_name);
    this.generateSearchLink();
  }

  selectedModel(event) {
    let current_input = event.target;
    let current_input_name = event.target.dataset.modelName;
    this.setFilterSearchParams("model", current_input.value, current_input.checked);
    this.generateSearchLink();
  }

  generateSearchLink(){
    let link_tag = document.getElementById("import-search-link");
    let url = new URL(window.location.href);
    let path = url.pathname;
    let search_params = new URLSearchParams(url.search);
    let search_keys = Object.keys(this.import_category_data);
    let max_keys = search_keys.length >= 1;
    let max_vals = ""
    search_keys.forEach((key) => {
        if(this.import_category_data[key]){
          let keys_with_vals = this.import_category_data[key].filter((val) => val !== null && val !== "");
            if(keys_with_vals.length > 0){
              search_params.set(key, keys_with_vals.join(","));
              if(max_keys && key !== "key"){
                max_vals += keys_with_vals.join(" ");
              }
            }else {
              search_params.delete(key);
            }
        }
    });
    if(max_vals){
      let key_val = this.findSessionFilters(max_vals)
      search_params.set("key", key_val);
    }
    link_tag.href = `${path}?${search_params.toString()}`;
  }

  hideShowSelectedMakeCheckboxes(target_element, name) {
    let element_id = concatName(name);
    let make_model_list = document.getElementById(`${element_id}-list`);
    let make_model_number = document.getElementById(`${element_id}-number`);
    let make_model_arrows = document.getElementById(`${element_id}-arrows`);
    let make_model_icon = document.getElementById(`${element_id}-icon`);

    if (target_element.checked) {
      replaceClassName(make_model_list, "d-none", "d-block");
      replaceClassName(make_model_number, "d-block", "d-none");
      replaceClassName(make_model_arrows, "d-none", "d-block");
    } else {
      replaceClassName(make_model_list, "d-block", "d-none");
      replaceClassName(make_model_number, "d-none", "d-block");
      replaceClassName(make_model_arrows, "d-block", "d-none");
    }
  }

  changeCollapseArrow(event) {
    let icon_1 = `<i class="fas fa-chevron-`;
    let icon_2 = `fs-12 text-gray22 pe-none"></i>`;
    let element = event.target;
    let arrow_type = element.dataset.arrow;
    if (arrow_type === "up") {
      element.innerHTML = `${icon_1}up ${icon_2}`;
      element.dataset.arrow = "down";
    } else if (arrow_type === "down") {
      element.innerHTML = `${icon_1}down ${icon_2}`;
      element.dataset.arrow = "up";
    }
  }

  sharedSelectedCheckbox(event) {
    let value = event.target.value;
    let name = event.target.name;
    let checked = event.target.checked;
    this.setFilterSearchParams(name, value, checked)
    this.generateSearchLink();
  }

  sharedNumberInputAction(event) {
    let value = event.target.value;
    let name = event.target.name;
    this.import_category_data[name] = null;
    this.setFilterSearchParams(name, value, true)
    this.generateSearchLink();
  }

  toggleFilterSwitch(event) {
    let element = event.target;
    let filter_name = element.name;
    let filter_checked = element.checked;
    let filter_value = filter_checked ? 'on' : null;
    this.setFilterSearchParams(filter_name, filter_value, filter_checked);
    this.generateSearchLink();
  }

  getActiveUrlFilters(){
    let url = new URL(window.location.href);
    let search_params = new URLSearchParams(url.search);
    let search_keys = search_params.keys();

    for (let key of search_keys) {
      let search_values = search_params.getAll(key)[0].split(",");
      this.import_category_data[key] = search_values;
      // get document input element with name {key} and check if it is value is in search_values
        let inputs = document.querySelectorAll(`input[name="${key}"]`);
        if(inputs){
          inputs.forEach((input) => {
            if(input.type === "checkbox"){
              if(input.name === 'model'){
                let input_make = input.dataset.makeModelName
                let active_make = this.import_category_data['make'].filter((make) => make.toLowerCase() === input_make.toLowerCase());
                if(active_make.length > 0 && search_values.includes(input.value)){
                  input.checked = true;
                }
              }
              else if(search_values.includes(input.value)){
                input.checked = true;
                if(key === 'make'){
                  this.hideShowSelectedMakeCheckboxes(input, input.dataset.makeName);
                }
              }
            }else if (input.type === "number"){
              input.value = search_values[0];
            }else {
              input.checked = search_values.includes(input.value);
            }
          });
        }
    }

    this.displayAvailableSearchParams();
    this.updateSortText();
  }

  displayAvailableSearchParams() {
    let filter_params_keys = Object.keys(this.import_category_data);
    let already_counted = [];
    filter_params_keys.forEach((fs) => {
      let name = fs;
      let has_min_max = fs.endsWith("_min") || fs.endsWith("_max");
      if(has_min_max){
        if(already_counted.includes(fs)){
          name = null;
        }else {
          name = fs.replace("_min", "").replace("_max", "");
          already_counted.push(name);
        }
      }

      if(name){
        let num_element = document.getElementById(`count-circle-${name}`);
        let text_element = document.getElementById(`selected-properties-${name}`);

        if(num_element && text_element){
          if (has_min_max) {
            let min_val = `${name}_min`;
            let max_val = `${name}_max`;
            let item_min = `${this.import_category_data[min_val]}`;
            let item_max = `${this.import_category_data[max_val]}`;
            let val_extension =
                name === "price" || name === "monthly"
                    ? "€"
                    : name === "year"
                        ? ""
                        : name === "mileage"
                            ? "km"
                            : name === "seats"
                                ? "hlö"
                                : name === "total_weight" ||
                                name === "towing_brakes" ||
                                name === "towing_no_brakes"
                                    ? "kg"
                                    : "";
            let min_item_value = item_min ? item_min : "0";
            let max_item_value = item_max ? item_max : "";
            text_element.textContent = `${min_item_value} ${val_extension} - ${max_item_value} ${val_extension}`;
            text_element.classList.remove("d-none");
            text_element.classList.add("d-block");
            num_element.classList.add("d-none");
          } else {
            num_element.textContent = this.import_category_data[fs].length;
            if (num_element.classList.contains("d-none")) {
              num_element.classList.remove("d-none");
              num_element.classList.add("d-block");
            }
            let text_cont_val = "";
            this.import_category_data[fs].forEach((val) => {
              let input = document.querySelector(`input[name="${fs}"][value="${val}"]`);
              if(input){
                let data_label = input.dataset.label;
                text_cont_val += `${data_label}, `;
              }
            });
            text_element.textContent =
                fs === "engine_size" ? `ale ${text_cont_val}.0` : text_cont_val;
          }
        }
      }
    });
  }

  sortCarsData(event) {
    let element = event.target;
    let sort_value = element.dataset.sortValue;
    let text_content = element.textContent;
    this.updateSortText(text_content);
    let url = new URL(window.location.href);
    let pathname = location.pathname;
    let search_params = new URLSearchParams(url.search);
    search_params.delete("key");
    search_params.delete("page");
    search_params.set("sort", sort_value);
    let new_key = `${pathname}?${search_params.toString()}`;
    let key_value = this.findSessionFilters(new_key);
    search_params.set("key", key_value);
    window.location.href = `${pathname}?${search_params.toString()}`;
  }

  updateSortText(text_content) {
    let sort_name_element = document.getElementById("current-sort-option");
    if(text_content){
      sort_name_element.textContent = text_content;
    }else {
        let url = new URL(window.location.href);
        let search_params = new URLSearchParams(url.search);
        let sort_value = search_params.get("sort");
        let sort_btn = document.querySelector(`button[data-sort-value="${sort_value}"]`);
        if(sort_btn && sort_value){
          sort_name_element.textContent = sort_btn.textContent;
        }else {
          sort_name_element.textContent = "Uusin ensin";
        }
    }
  }

  startSearch(event) {
    event.preventDefault();
    let page = this.import_category_data["page"]
    if(page && parseInt(page) > 1) {
      this.import_category_data["page"] = [1];
      this.generateSearchLink();
    }
    window.location.href = event.target.href;
  }

  replacePaginationLinks() {
    let container = document.getElementById("import-car-pagination");
    if (container) {
      let url = new URL(window.location.href);
      let path = url.pathname;
      let links = container.querySelectorAll("a");
      // Get pathname and remove key from search params and join to the pathname
      let pathname = location.pathname;
      let search_params = new URLSearchParams(url.search);
      search_params.delete("key");
      search_params.delete("page");
      let new_key = `${pathname}?${search_params.toString()}`;
      links.forEach((link) => {
        let href = link.href;
        let new_url = new URL(href);
        let page = new_url.searchParams.get("page");
        new_url.searchParams.delete("key");
        let saved_value = this.findSessionFilters(`${new_key}_${page}`);
        new_url.searchParams.set("key", saved_value);
        new_url.pathname = path;
        link.href = new_url;
      });
    }
  }

  stringToSlug(string) {
    return string.toLowerCase().replace(/\s/g, "-");
  }

  findSessionFilters(key){
    let session_filters = JSON.parse(sessionStorage.getItem("session_filters"));
    let value = this.randomString();
    if(session_filters){
      if(session_filters[key]){
        value = session_filters[key];
      }else {
        session_filters[key] = value;
      }
    }else {
      session_filters = {[key]: value};
    }

    sessionStorage.setItem("session_filters", JSON.stringify(session_filters));

    return value;
  }

  // Create random string about 10 characters long
    randomString() {
      let random_string = Math.random().toString(36).substring(2, 15);
      return random_string;
    }
}
