// javascript/controllers/cosmetic_remarks_controller.js
import {Controller} from "stimulus";

export default class extends Controller {

    static targets = ["imginside", "imgoutside"];

    current_side = "inside";

    connect() {
    }

    showImage(e) {
        let element = e.target;
        let url = element.dataset.dotUrl;
        let id = element.id;

        this.updateDotImageSection(this.current_side, url);
    }

    updateDotImageSection(side, url){
        if (side === 'inside'){
            this.imginsideTarget.src = url;
        }

        if (side === 'outside'){
            this.imgoutsideTarget.src = url;
        }
    }

    updateCurrentSide(e){
        let element = e.target;
        this.current_side = element.dataset.side;
    }

}
