// javascript/controllers/scrollable_controller.js
import {Controller} from "stimulus";
import {calculatedViewport} from "./../helpers";

export default class extends Controller {
  static targets = ["scrollableContainer"];

  is_covered = true;
  scrollHorizontal(event) {
    let current_target = event.currentTarget;
    let { width } = calculatedViewport();
    let container_children = current_target.querySelectorAll(
      ".scrollable-child"
    );
    let first_child = container_children[0];
    let last_child = container_children[container_children.length - 1];
    let { x: first_child_x } = first_child.getBoundingClientRect();
    let { x: last_child_x } = last_child.getBoundingClientRect();

    current_target.style.scrollBehavior = 'smooth';

    if (first_child_x - 80 < 0 || last_child_x + 250 > window.innerWidth) {
      if(current_target.dataset.options !== 'mobile'){
        event.preventDefault();
      }else {
        if(width <= 767){
          event.preventDefault();
        }
      }
      if (event.deltaY > 0) this.scrollableContainerTarget.scrollLeft += 250;
      else this.scrollableContainerTarget.scrollLeft -= 250;
    }
  }

  scrollListHorizontally(event) {
    let container = document.getElementById("images-list-scrollable-container");
    if (container) {
      let container_children = container.querySelectorAll(".img-li");
      let first_child = container_children[0];
      let last_child = container_children[container_children.length - 1];
      let { x: first_child_x } = first_child.getBoundingClientRect();
      let { x: last_child_x } = last_child.getBoundingClientRect();

      if (first_child_x - 50 < 0 || last_child_x + 200 > window.innerWidth) {
        event.preventDefault();
        if (event.deltaY > 0) container.scrollLeft += 100;
        else container.scrollLeft -= 100;
      }
    }
  }

  scrollListHorizontallyWithBtn(event) {
    let element = event.target
    let side = element.dataset.side;
    let container = document.getElementById("images-list-scrollable-container");
    if (container) {
      let container_children = container.querySelectorAll(".img-li");
      let first_child = container_children[0];
      let last_child = container_children[container_children.length - 1];
      let { x: first_child_x } = first_child.getBoundingClientRect();
      let { x: last_child_x } = last_child.getBoundingClientRect();

      if(side === 'left'){
        container.scrollLeft -= 200;
      }

      if(side === 'right'){
        container.scrollLeft += 200;
      }
    }
  }

  // Toggling table cover
  toggleCover(event){
    let element = event.target;
    let container = document.getElementById("huoleton-section");
    let blur_cover = document.getElementById("huoleton-gradient-table-cover");

    this.is_covered = !this.is_covered;

    if(this.is_covered){
      element.innerHTML = `<i class="fas fa-chevron-down text-gray67 pe-none"></i>`;
      container.classList.add("height-280");
      container.classList.add("overflow-hidden-y");
      blur_cover.classList.remove("d-none");
    }else {
      element.innerHTML = `<i class="fas fa-chevron-up text-gray67 pe-none"></i>`;
      container.classList.remove("height-280");
      container.classList.remove("overflow-hidden-y");
      blur_cover.classList.add("d-none");
    }
  }

  scrollParent(event) {
    let target = event.target;
    let direction = target.dataset.direction;
    let parent = document.getElementById(target.dataset.parent);
    parent.style.scrollBehavior = 'smooth';
    if(direction === 'previous'){
      parent.scrollLeft -= 200;
    }
    if(direction === 'next'){
      parent.scrollLeft += 200;
    }
  }
}
