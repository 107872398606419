import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "carPrice",
    "downPayment",
    "loanMonths",
    "lastPayment",
    "monthlyHandlingFee",
    "accountOpenFee",
    "annualInterest"
  ];

  calculate() {
    const carPrice = parseFloat(this.carPriceTarget.value);
    const downPayment = parseFloat(this.downPaymentTarget.value);
    const loanMonths = parseInt(this.loanMonthsTarget.value);
    const lastPayment = parseFloat(this.lastPaymentTarget.value);
    const monthlyHandlingFee = parseFloat(this.monthlyHandlingFeeTarget.value);
    const accountOpenFee = parseFloat(this.accountOpenFeeTarget.value);
    const annualInterest = parseFloat(this.annualInterestTarget.value);

    // Example calculation logic
    const principal = carPrice - downPayment;
    const monthlyInterestRate = (annualInterest / 100) / 12;
    const monthlyPayment = (principal * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -loanMonths));
    const totalPayment = (monthlyPayment + monthlyHandlingFee) * loanMonths + lastPayment + accountOpenFee;

    this.displayResult(totalPayment, monthlyPayment);
  }

  displayResult(totalPayment, monthlyPayment) {
    const resultElement = document.getElementById("result");

    const carPrice = parseFloat(this.carPriceTarget.value);
    const downPayment = parseFloat(this.downPaymentTarget.value);
    const loanMonths = parseInt(this.loanMonthsTarget.value);
    const lastPayment = parseFloat(this.lastPaymentTarget.value);
    const annualInterest = parseFloat(this.annualInterestTarget.value);
    const monthlyHandlingFee = parseFloat(this.monthlyHandlingFeeTarget.value);
    const accountOpenFee = parseFloat(this.accountOpenFeeTarget.value);
    const principal = carPrice - downPayment;
    const totalCostOfLoan = totalPayment - principal;
    const effectiveAnnualInterestRate = (Math.pow(1 + (annualInterest / 100) / 12, 12) - 1) * 100;
    const monthlyInterestRate = (annualInterest / 100) / 12;
    const monthlyPaymentWithFees = this.calculateMonthlyPaymentWithFees(principal + accountOpenFee, monthlyInterestRate, loanMonths, monthlyHandlingFee);
    const totalPaymentWithFees = monthlyPaymentWithFees * loanMonths + accountOpenFee;

    const effectiveApr = this.calculateEffectiveInterestRate(principal, monthlyPaymentWithFees, loanMonths);

    const monthlyPaymentWithFeesAndBalloon = this.calculateMonthlyPaymentWithFeesAndBalloon(principal + accountOpenFee, monthlyInterestRate, loanMonths, monthlyHandlingFee, lastPayment);
    const totalPaymentWithFeesAndBalloon = monthlyPaymentWithFeesAndBalloon * loanMonths + lastPayment + accountOpenFee;
    const effectiveAprWithBalloon = this.calculateEffectiveInterestRateWithBalloon(principal, monthlyPaymentWithFeesAndBalloon, loanMonths, lastPayment);
    // <p>Part to be financed: $${principal.toFixed(2)}</p>
    // <p>Monthly payment: $${monthlyPayment.toFixed(2)}</p>
    // <p>Total to pay: $${totalPayment.toFixed(2)}</p>
    // <p>Total cost of loan: $${totalCostOfLoan.toFixed(2)}</p>
    // <p>Effective annual interest rate according to KSL: ${effectiveAnnualInterestRate.toFixed(2)}%</p>
    // <p>The last installment: $${lastPayment.toFixed(2)}</p>
    resultElement.innerHTML = `
      <p>--------------------------------</p>
      <p>Monthly payment with fees: $${monthlyPaymentWithFees.toFixed(2)}</p>
      <p>Total to pay with fees: $${totalPaymentWithFees.toFixed(2)}</p>
      <p>Approximate APR: ${effectiveApr.toFixed(2)}%</p>
      <p>--------------------------------</p>
      <p class="fs-18 fw-bold">Kuukausierä : Monthly payment with fees and balloon: $${monthlyPaymentWithFeesAndBalloon.toFixed(2)}</p>
      <p class="fs-18 fw-bold">Total to pay with fees and balloon: $${totalPaymentWithFeesAndBalloon.toFixed(2)}</p>
      <p >Approximate APR: ${effectiveAprWithBalloon.toFixed(2)}%</p>
    `;
  }
  // Function to calculate monthly payment with fees
  calculateMonthlyPaymentWithFees(P, r, n, fee) {
    const basePayment = P * r * Math.pow(1 + r, n) / (Math.pow(1 + r, n) - 1); // Standard loan monthly payment
    return basePayment + fee; // Add monthly fee to each payment
  }

  calculateMonthlyPaymentWithFeesAndBalloonOld(P, r, n, fee, balloonPayment) {
    // Calculate the present value of the loan amount minus the balloon payment
    const loanAmountForPayments = P - balloonPayment / Math.pow(1 + r, n);
    
    // Calculate the standard monthly payment without the balloon payment
    const basePayment = loanAmountForPayments * r * Math.pow(1 + r, n) / (Math.pow(1 + r, n) - 1);
    
    // Return the total monthly payment including the fee
    return basePayment + fee;
}

  calculateMonthlyPaymentWithFeesAndBalloon(P, r, n, fee, balloonPayment) {
    // Calculate the present value of the balloon payment
    const pvBalloonPayment = balloonPayment / Math.pow(1 + r, n);

    // Calculate the effective loan amount that will be financed through regular payments
    const effectiveLoanAmount = P - pvBalloonPayment;

    // Calculate the standard loan monthly payment
    // const basePayment = effectiveLoanAmount * r * Math.pow(1 + r, n) / (Math.pow(1 + r, n) - 1);
    const basePayment = (effectiveLoanAmount * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    
    // Return total monthly payment including the fee
    return basePayment + fee; // Add monthly fee to each payment
}


  calculateEffectiveInterestRate(loanAmount, monthlyPayment, numberOfPayments) {
    const tolerance = 0.00001; // For precision in finding the rate
    let low = 0.0; // Start of the search range for the monthly interest rate
    let high = 1.0; // End of the search range for the monthly interest rate
    let monthlyRate = 0.0; // Monthly interest rate to be determined

    // Use binary search to find the monthly interest rate
    while (high - low > tolerance) {
        monthlyRate = (low + high) / 2; // Midpoint
        const presentValue = monthlyPayment * (1 - Math.pow(1 + monthlyRate, -numberOfPayments)) / monthlyRate;

        if (presentValue < loanAmount) {
            high = monthlyRate; // Lower the high bound
        } else {
            low = monthlyRate; // Raise the low bound
        }
    }

    // Calculate the effective annual interest rate
    const effectiveAnnualRate = Math.pow(1 + monthlyRate, 12) - 1;

    return effectiveAnnualRate * 100; // Return as a percentage
  }

calculateEffectiveInterestRateWithBalloon(loanAmount, monthlyPayment, numberOfPayments, balloonPayment) {
    const tolerance = 0.00001; // For precision in finding the rate
    let low = 0.0; // Start of the search range for the monthly interest rate
    let high = 1.0; // End of the search range for the monthly interest rate
    let monthlyRate = 0.0; // Monthly interest rate to be determined

    // Use binary search to find the monthly interest rate
    while (high - low > tolerance) {
        monthlyRate = (low + high) / 2; // Midpoint
        
        // Calculate the present value of the monthly payments and the balloon payment
        const presentValue = (monthlyPayment * (1 - Math.pow(1 + monthlyRate, -numberOfPayments)) / monthlyRate) +
                             (balloonPayment / Math.pow(1 + monthlyRate, numberOfPayments));

        if (presentValue < loanAmount) {
            high = monthlyRate; // Lower the high bound
        } else {
            low = monthlyRate; // Raise the low bound
        }
    }

    // Calculate the effective annual interest rate
    const effectiveAnnualRate = Math.pow(1 + monthlyRate, 12) - 1;

    return effectiveAnnualRate * 100; // Return as a percentage
}

}