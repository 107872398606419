// javascript/controllers/hero_controller.js
import {Controller} from "stimulus";
import {addDarkTheme, addLightTheme} from "./../helpers";

export default class extends Controller {
  static targets = ["hero"];

  initialize() {}

  dismissExtraFilters(event) {
    let nav = document.getElementById("navigation");
    let main_search_bar = document.getElementById("main-search-bar-section");
    let ready_made_filters_container = document.getElementById(
      "ready-filters-container"
    );
    if (event.clientY > 450 && main_search_bar.classList.contains("bg-white")) {
      main_search_bar.classList.add("bg-transparent");
      main_search_bar.classList.remove("shadow");
      main_search_bar.classList.remove("bg-white");
      ready_made_filters_container.classList.add("height-0");
      addLightTheme(nav);
    }

    if(!main_search_bar.classList.contains("bg-white")){
      let container = document.getElementById('frontpage-contact-container')
      if(container){
        container.classList.remove('d-none')
      }
    }
  }

  // Sticky navbar after scroll
  freezeNavbar() {
    let nav = document.getElementById("navigation");
    let main_search_bar = document.getElementById("main-search-bar-section");
    let hero = this.heroTarget;
    let hero_height = hero.scrollHeight;
    let size = hero_height - window.scrollY;
    let ready_filtered_landing = document.getElementById("minimize-search-bar");

    if (size < 30) {
      addDarkTheme(nav);
    } else {
      if (size && ready_filtered_landing) {
        addLightTheme(nav);
      }
      if (main_search_bar) {
        let has_white_bg = main_search_bar.classList.contains("bg-white");
        if (!has_white_bg) {
          addLightTheme(nav);
        }
      }
    }
  }

  addStickyNavbar(){
    let nav = document.getElementById("navigation");
    let company_hero = document.getElementById("company-hero");
    if(company_hero){
      let company_hero_height = company_hero.scrollHeight;
      let company_hero_size = company_hero_height - window.scrollY;

      if (company_hero_size < 30) {
        addDarkTheme(nav);
      }else {
        addLightTheme(nav);
      }
    }
  }
}
