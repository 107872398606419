// javascript/controllers/reclamations_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {validateEmail} from "../helpers"

export default class extends Controller {
    static targets = ["noInsuranceDetails", "withInsuranceDetails", "previous", "next", "submitBtn"];
    fields_with_errors = [];
    step = 1;
    cost_confirmed = false;
    reclamation = {
        name: "",
        email: "",
    };

    collected_files = []
    faults_located = false;
    previous_activated = false;

    connect(){
        this.toggleFaultsNotLocatedInputs("hide");
    }

    tabsInfoAndContent(){
        //Tabs
        let more_info = document.getElementById("pills-more-info-tab");
        let car_info = document.getElementById("pills-car-info-tab");
        let faults = document.getElementById("pills-fault-tab");
        let summary = document.getElementById("pills-summary-tab");

        //Tab content
        let pills_more_info = document.getElementById("pills-more-info");
        let pills_car_info = document.getElementById("pills-car-info");
        let pills_fault = document.getElementById("pills-fault");
        let pills_summary = document.getElementById("pills-summary");

        return {more_info, car_info, faults, summary, pills_more_info, pills_car_info, pills_fault, pills_summary}
    }

    nextSection(e) {

        let {more_info, car_info, faults, summary, pills_more_info, pills_car_info, pills_fault, pills_summary} = this.tabsInfoAndContent();

        if(this.step === 3){
            this.changeTabContent(faults, summary, pills_fault, pills_summary);
            e.target.classList.add('d-none');
            e.target.classList.add('disabled');
            if(this.previous_activated){
                this.submitBtnTarget.classList.remove('d-none');
                this.nextTarget.classList.add('d-none');
                this.nextTarget.classList.add('disabled');
            }
            this.step += 1;
        }

        if(this.step === 2){
            let reg_num = document.getElementById("reclamation_reg_num");
            let make_model = document.getElementById("reclamation_make_model");
            let buy_date = document.getElementById("reclamation_buy_date");
            let price = document.getElementById("reclamation_price");
            let km = document.getElementById("reclamation_km");

            let validated = this.validateFields([reg_num, make_model, buy_date, price, km]);
            if(validated){
                this.changeTabContent(car_info, faults, pills_car_info, pills_fault);
                this.step += 1;
            }
        }

        if(this.step === 1){
            let name = document.getElementById("reclamation_name");
            let email = document.getElementById("reclamation_email");
            let phone = document.getElementById("reclamation_phone");
            let cost_estimate = document.getElementById("reclamation_cost_estimate");
            let fault_checked_yes = document.getElementById("fault_checked_yes");

            if(!validateEmail(email.value)){
                email.classList.add('is-invalid');
            }else {
                let validated = this.validateFields([name, email, phone]);
                if(this.faults_located){
                    validated = this.validateFields([name, email, phone, cost_estimate]);
                }
                if(validated){
                    if(this.cost_confirmed){
                        this.changeTabContent(more_info, car_info, pills_more_info, pills_car_info);
                        this.step += 1;
                    }else {
                        fault_checked_yes.classList.add('is-invalid');
                    }
                }else {
                    fault_checked_yes.classList.add('is-invalid');
                }
            }

            this.previousTarget.classList.remove('d-none');
        }
    }

    previousSection(){
        let {more_info, car_info, faults, summary, pills_more_info, pills_car_info, pills_fault, pills_summary} = this.tabsInfoAndContent();
        this.previous_activated = true;
        if(this.step > 1 && this.step < 5){
            switch (this.step) {
                case 2:
                    this.changeTabContent(more_info, car_info, pills_more_info, pills_car_info, 'previous');
                    this.previousTarget.classList.add('d-none');
                    break;
                case 3:
                    this.changeTabContent(car_info, faults, pills_car_info, pills_fault, 'previous');
                    break;
                case 4:
                    this.changeTabContent(faults, summary, pills_fault, pills_summary, 'previous');
                    this.nextTarget.classList.remove('d-none');
                    this.nextTarget.classList.remove('disabled');
                    break;
            }
            this.step -= 1;
        }
    }

    updateFiles(event){
        let message = document.getElementById('rec_att_files_msg');
        message.textContent = "";
        if(this.collected_files.length > 14){
            message.textContent = `Olet ladannut enimmäismäärän tiedostoja`;
            return;
        }
        let element = event.target;
        let files = element.files;
        for(let i = 0; i < files.length; i++){
            let file = element.files[i];
            let is_uploaded = this.checkIsFileAvailable(file);
            if(is_uploaded){
                message.textContent = `Tiedosto (${is_uploaded.name}) on jo ladattu.`;
            }else {
                if(file.size < 5000000){
                    this.collected_files.unshift(file)
                }else {
                    message.textContent = `Tiedosto (${file.name}) on liian suuri.`;
                }
            }
        }
        this.createAttachmentPreview();
    }

    checkIsFileAvailable(file){
        let file_name = file.name;
        return this.collected_files.find(({name}) => name === file_name)
    }

    createAttachmentPreview(){
        let prev_container = document.getElementById('rec_att_files_preview');
        let htm_content = "";
        this.collected_files.forEach((file, index) => {
            let src = URL.createObjectURL(file);
            let file_type = file.type;
            let img = "";
            if(file_type === 'application/pdf'){
                img = `<div class="col-6 col-sm-3 col-md-2 mb-2">
                        <div class="w-100 full-height d-flex flex-column">
                            <div class="border border-gray22 p-2 flex-grow-1">
                                <p class="fs-12 text-warning fw-semi-bold">Lataus onnistui.</p>
                                <p class="fs-12 text-warning fw-semi-bold">Ei ole esikatselua</p>
                                <p class="fs-12 text-break">${file.name}</p>
                            </div>
                            <div class="w-100 d-grid my-1">
                                <button class="btn btn-danger btn-sm rounded-0 fs-12 my-2" data-name="${file.name}" data-action="reclamations#removeFile">Poista tiedosto</button>
                            </div>
                            </div>
                        </div>`;
            }else {
                img = `<div class="col-6 col-sm-3 col-md-2 mb-2">
                        <div class="w-100 full-height d-flex flex-column">
                              <div class=" border border-gray22 flex-grow-1">
                                <img src="${src}" class="img-fluid" />
                               </div>
                                <div class="d-grid my-2">
                                    <button class="btn btn-danger btn-sm rounded-0 fs-12 my-2" data-name="${file.name}" data-action="reclamations#removeFile">Poista tiedosto</button>
                                </div>
                            </div>
                          </div>`;
            }
            htm_content += img;
        });
        prev_container.innerHTML = htm_content;
    }

    removeFile(event){
        let element = event.target;
        let name = element.dataset.name;
        this.collected_files = this.collected_files.filter(file => file.name !== name);
        this.createAttachmentPreview();
    }

    submitReclamationForm(event){
        event.preventDefault();
       let form = document.getElementById("reclamation-form");
       let rec_cover = document.getElementById("rec-cover");
       let formData = new FormData(form);
       if(this.collected_files.length > 0){
           this.collected_files.forEach((file, index) => {
               formData.append('files[]', file)
           })
       }
        let inputs = form.elements;
        Array.from(inputs).forEach(input => {
            input.disabled = true;
        });
        rec_cover.classList.remove('d-none');
       Rails.ajax({
           type: 'POST',
           url: "/reklamaatio.json",
           data: formData,
           error: (response) => {
               let {status} = response;
               if(status === 'error'){
                   let response_message = document.getElementById('response_msg');
                   response_message.textContent = "Virhe lomakkeen lähettämisessä. Ole hyvä ja yritä uudelleen.";
               }
           },
           success: (response) => {
               let {status} = response;
               if(status === 'success'){
                   location.href = "/kiitos"
               }
               if(status === 'error'){
                   let response_message = document.getElementById('response_msg');
                   response_message.textContent = "Virhe lomakkeen lähettämisessä. Ole hyvä ja yritä uudelleen.";
                   Array.from(inputs).forEach(input => {
                       input.disabled = false;
                   });
                   rec_cover.classList.add('d-none');
               }
           }
       })
    }

    validateFields(items){
        let validated = true;
        items.forEach(field => {
            if(!field.value){
                field.classList.add('is-invalid');
                validated = false;
            }
        });

        return validated;
    }

    costEstimationConfirmed(e) {
        let element = e.target.value;
        let cost_container = document.getElementById("cost-estimation-container");
        let modal_btn = document.getElementById("new_recalamation_modal_btn");
        let continue_btn = document.getElementById("continue_btn");
        if(element === 'yes')
        {
            this.cost_confirmed = true;
            this.faults_located = true;
            this.toggleFaultsNotLocatedInputs("hide");
            //cost_container.classList.remove('d-none');
            //continue_btn.classList.remove('d-none');
            //e.target.classList.remove('is-invalid');
        }
        if(element === 'no')
        {
            this.cost_confirmed = true;
            this.faults_located = false;
            this.toggleFaultsNotLocatedInputs("show");
            //cost_container.classList.add('d-none');
            //continue_btn.classList.add('d-none');
            modal_btn.click();
        }
    }

    changeTabContent(previousTab, currentTab, previousTabContent, currentTabContent, action="next"){
        if(action === 'next'){
            previousTab.classList.remove('active');
            previousTab.classList.add('bg-primary');
            previousTab.classList.add('text-white');
            previousTab.classList.add('disabled');
            previousTab.ariaSelected = false;
            currentTab.classList.add('active');
            currentTab.ariaSelected = true;
            previousTabContent.classList.remove('show');
            previousTabContent.classList.remove('active');
            currentTabContent.classList.add('show');
            currentTabContent.classList.add('active');
        }else if(action === 'previous'){
            previousTab.classList.add('active');
            previousTab.ariaSelected = true;
            previousTab.classList.remove('bg-primary');
            previousTab.classList.remove('text-white');
            previousTab.classList.remove('disabled');
            previousTab.ariaSelected = false;
            currentTab.classList.remove('active');
            previousTabContent.classList.add('show');
            previousTabContent.classList.add('active');
            currentTabContent.classList.remove('show');
            currentTabContent.classList.remove('active');
        }
    }

    removeErrorIndicator(event) {
        let ele_id = event.target.id;
        if (ele_id) {
            let current_element = document.getElementById(ele_id);
            if (current_element) {
                current_element.classList.remove("is-invalid");
            }
        }
    }

    showImportedMessage(event) {
        let submit_btn = document.getElementById(
            "sell-request-submit-btn-container"
        );
        let message = document.getElementById("imported_car_message");
        let yes_checkbox = document.getElementById("imported_car_yes");
        let no_checkbox = document.getElementById("imported_car_no");
        let value = event.target.value;
        if (value === "yes") {
            submit_btn.classList.add("d-none");
            message.classList.remove("d-none");
            if (no_checkbox.checked) {
                no_checkbox.checked = false;
            }
        } else {
            submit_btn.classList.remove("d-none");
            message.classList.add("d-none");
            if (yes_checkbox.checked) {
                yes_checkbox.checked = false;
            }
        }
    }

    toggleInsuranceInfo(e){
        let val = e.target.value;
        let no_insurance = this.noInsuranceDetailsTarget
        let with_insurance = this.withInsuranceDetailsTarget
        if(no_insurance && with_insurance){
            if(val === 'Ei ole'){
                no_insurance.classList.remove('d-none')
                with_insurance.classList.add('d-none');
            }else {
                with_insurance.classList.remove('d-none')
                no_insurance.classList.add('d-none')
            }
        }
    }

    toggleFaultsNotLocatedInputs(action){
        let fields = document.querySelectorAll(".faults-not-located");
        let faults_located = document.querySelector(".faults-located");
        if(action === 'show'){
            Array.from(fields).forEach(field => {
                field.classList.remove('d-none');
            })
            faults_located.classList.add("d-none");
        }else {
            Array.from(fields).forEach(field => {
                field.classList.add('d-none');
                faults_located.classList.remove("d-none")
            })
        }
    }
}
