// javascript/controllers/detail_banner_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
    static targets = [];

    updateContactFormCategory(e){
        let target = e.target;
        let category = target.dataset.category;
        let container = document.getElementById("contactFormModal");
        let input = container.querySelector("input[name='category']");
        input.value = category;
    }
}