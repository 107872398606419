// javascript/controllers/navbar_controller.js
import {Controller} from "stimulus";
import {addDarkTheme, addLightTheme, breadCrumbToName, getCookie, setCookie} from "../helpers";

export default class extends Controller {
  static targets = [
    "burger",
    "collapsible",
    "content",
    "navigation",
    "audience",
  ];

  // Get the names of the current url
  pathname = null;
  ALERT_NAME = "u8KpdgptxVZCJa4qbP4UFz2Z";
  connect() {
    this.pathname = location.pathname.split("/").filter(Boolean);
    this.paytrailInfoToggle();
    this.showActiveNavLink();
    //this.updateNavbarThemeForCompanySection();
    this.showGlobalAlertMessage();
    this.addBackgroundGradient();
    this.createBreadCrumb();
    let has_admin_pathname = location.pathname.split("/").indexOf("admin") >= 0;
    if(has_admin_pathname){
      let video_items_container = document.querySelectorAll(".video-items-container")
      Array.from(video_items_container).forEach(video => {
        let video_item = video.querySelector(".video-items")
        video_item.classList.remove("d-none")
      })
    } else {
      this.updateIFramesVisibility();
    }
  }

  // Toggling hamburger menu
  toggleMenu() {
    let menu_btn = this.burgerTarget;
    let collapsible = this.collapsibleTarget;
    let content = this.contentTarget;
    menu_btn.classList.toggle("navigation__burger-active");
    if (menu_btn.classList.contains("navigation__burger-active")) {
      collapsible.style.width = "100%";
      content.style.display = "block";
    } else {
      content.style.display = "none";
      collapsible.style.width = "0";
    }
  }

  toggleDrawer(event) {
    event.stopPropagation();
  }

  // Tracks main window width size. Used to determine mobile styles
  mobileViewTracker() {
    /*     let nav = document.getElementById("navigation");
    if (window.innerWidth > 524 && this.pathname.length < 1) {
      addLightTheme(nav);
    } else {
      addDarkTheme(nav);
    } */
  }

  // Shows current active link
  showActiveNavLink() {
    let controller = this;
    let nav = document.getElementById("navigation");
    let links = nav.querySelectorAll(".nav-link");
    let main_hero_header = document.getElementById("main-hero-header");
    let mini_hero_header = document.getElementById("company-hero");

    if (this.pathname.length > 0 && !this.pathname.includes("yksityisille")) {
      addDarkTheme(nav);
    }

    if (
      (this.pathname.length > 0 && main_hero_header) ||
      (this.pathname.length > 0 && mini_hero_header)
    ) {
      addLightTheme(nav);
    }

    links.forEach(function (link) {
      let current_url = link.href.split("/")
      let main = document.getElementsByTagName('main')[0]
      let link_name = null;
      if(main){
        link_name = main.dataset.urlname;
      }
      if (link_name && current_url.includes(link_name)) {
        link.classList.add("navigation__active");
      }
    });
  }

  updateAudienceDropdown(pathname) {
    let au_dropdown = this.audienceTarget;
    if (pathname.includes("yrityksille")) {
      au_dropdown.innerText = "Yrityksille";
    } else {
      if (au_dropdown.innerText === "") au_dropdown.innerText = "Yksityisille";
    }
  }

  updateNavbarThemeForCompanySection() {
    let ele = document.getElementById("company-hero");
    if (ele) {
      let nav = document.getElementById("navigation");
      addLightTheme(nav);
    }
  }

  showGlobalAlertMessage() {
    let alert_container = document.getElementById(
      "navigation-global-alert-container"
    );
    let cookie_available = getCookie(this.ALERT_NAME);

    if (cookie_available) {
      alert_container.classList.add("d-none");
    } else {
      alert_container.classList.remove("d-none");
    }
  }

  closeAlertMessage() {
    let nav_items_container = document.getElementById(
      "navigation-global-alert"
    );
    setCookie(this.ALERT_NAME, "jemSV853rF9mexBcMJnHzJYu", 0.25);
    nav_items_container.classList.add("d-none");
  }

  addBackgroundGradient() {
    let gp_page = document.getElementById("general-purpose-page-nav-container");
    let mini_hero_header = document.getElementById("company-hero");
    if (gp_page && mini_hero_header) {
      gp_page.classList.add("navigation-bg");
    }
  }

  createBreadCrumb() {
    let list = document.getElementById("page-with-indent-breadcrumb");
    let html = "";
    if (list && this.pathname) {
      let last_index = this.pathname.length - 1;
      let url = "";
      this.pathname.forEach((path, index) => {
        url += `/${path}`
        html += `<li class="breadcrumb-item ${
          last_index === index && "active"
        }"><a class="text-decoration-none" href="${url}">${breadCrumbToName(path)}</a></li>`;
      });
      list.innerHTML = html;
    }
  }

  paytrailInfoToggle(){
    let myCollapsible = document.getElementById('collapseCompanyInfo')
    let myCollapseCommand = document.getElementById('showCompanyInfo')
    if(myCollapsible && myCollapseCommand){
      myCollapsible.addEventListener('hidden.bs.collapse', function () {
        myCollapseCommand.innerHTML="(Näytä tiedot)";
      })
      myCollapsible.addEventListener('shown.bs.collapse', function () {
        myCollapseCommand.innerHTML="(Piilota tiedot)";
      })
    }
  }

  updateIFramesVisibility(){
    let video_items_container = document.querySelectorAll(".video-items-container")
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if(entry.isIntersecting){
          let video_item = entry.target.querySelector(".video-items")
          video_item.classList.remove("d-none")
        }
      });
    }, {});

    Array.from(video_items_container).forEach(video => {
      observer.observe(video);
    })
  }
}
