// javascript/controllers/car_promotions_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = [];
    main_container = null;
    promotion_info = {
        id: null,
        url: null,
        page_size: null,
        display_mode: "",
        is_epa: null
    }

    connect() {
        this.main_container = this.element;
        // get span element with class epa-promotion
        let info_span = this.main_container.querySelector(".promotion-info");
        this.promotion_info = {
            id: info_span.dataset.code,
            url: info_span.dataset.page,
            page_size: info_span.dataset.limit,
            display_mode: info_span.dataset.mode,
            is_epa: info_span.dataset.isEpa
        }

        if(this.promotion_info.is_epa === "true"){
            this.getVehiclesList();
        }
    }

    getVehiclesList() {
        let controller = this;
        let list = controller.element.querySelector(`.import_cars_${this.promotion_info.display_mode}`)
        let url = new URL(this.promotion_info.url);
        let searchParams = new URLSearchParams(url.search);
        searchParams.set("key", this.promotion_info.id);
        searchParams.set("page_size", this.promotion_info.page_size);
        searchParams.set("display_mode", this.promotion_info.display_mode);
        Rails.ajax({
            url: "/tuontiautot/promotion_vehicles?" + searchParams.toString(),
            type: "GET",
            success: (response) => {
                const { status, data, total_vehicles } = response;
                list.innerHTML = data;
            },
            error: (error) => {
                console.log("Error: ", error);
            }
        });
    }

    scrollLeft(e){
        let id = e.target.dataset.elementId
        let container = document.getElementById(`active_promotion_container_${id}`)
        container.style.scrollBehavior = 'smooth'
        container.scrollLeft -= 320;
        container.style.transitionDuration = "0.8s";
    }

    scrollRight(e){
        let id = e.target.dataset.elementId
        let container = document.getElementById(`active_promotion_container_${id}`)
        container.style.scrollBehavior = 'smooth'
        container.scrollLeft += 320;
        container.style.transitionDuration = "0.8s";
    }
}
