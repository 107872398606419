// javascript/controllers/general_contacts_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {enableDisableFormFields, validateEmail, validatePhone} from "../helpers/index";

export default class extends Controller {
    fields_with_errors = ["_own_info_name", "_own_info_phone", "_own_info_email",
        "g_message", "g_terms_accepted"];
    on_behalf_fields = ["_on_behalf_info_name", "_on_behalf_info_phone", "_on_behalf_info_email"];
    fileds_to_be_validated = []
    terms_accepted = false;
    connect(){
        this.fileds_to_be_validated = this.fields_with_errors;
    }

    chooseUser(event){
        let friend_fields = ["_on_behalf_info_name", "_on_behalf_info_phone", "_on_behalf_info_email"];
        let val = event.target.value;
        let friend_container = document.getElementById('friends-container');
        let own_family = document.getElementById('own_family_checkbox');
        let friend_family = document.getElementById('friend_family_checkbox');
        if(val === 'friend_family' && event.target.checked){
            friend_container.classList.remove('d-none');
            friend_container.classList.remove('invisible');
            own_family.checked = false;
            this.fileds_to_be_validated = [...this.fields_with_errors, ...this.on_behalf_fields]
        }else {
            this.fileds_to_be_validated = this.fields_with_errors;
            friend_container.classList.add('d-none');
            friend_container.classList.add('invisible');
            friend_family.checked = false;
            friend_fields.forEach(id => {
                let element = document.getElementById(id)
                element.value = "";
            })
        }
    }

    submitForm(event) {
        event.preventDefault();
        let form = document.getElementById('general-contact-form')
        let form_url = form.action;
        let form_data = new FormData(form);
        if(!this.validateFormFields(form)){
            return;
        }
        enableDisableFormFields(form, "disable");
        Rails.ajax({
            type: "post",
            url: form_url,
            data: form_data,
            success: (response) => {
                let { status, data } = response;
            },
            error: (response) => {
                enableDisableFormFields(form, "enable");
                let error_fields = Object.keys(response); //.map(
                //  (key) => `sell_request_${key}`
                //);
                error_fields.forEach((field_name) => {
                    let ele = document.getElementById(field_name);
                    if (ele) {
                        ele.classList.add("is-invalid");
                    }
                });
                this.fields_with_errors = error_fields;
                if (error_fields.length > 0) {
                    let first_element = document.getElementById(error_fields[0]);
                    first_element.scrollIntoView();
                }
            },
        });
    }

    termsAccepted(event){
        this.terms_accepted = !!event.target.checked;
    }

    validateFormFields(form){
        this.fileds_to_be_validated.forEach(id => {
            let element = document.getElementById(id)
            if(id.split("_").indexOf('email') >= 0){
                if(!validateEmail(element.value)){
                    element.classList.add('is-invalid')
                }
            }
            if(id.split("_").indexOf('phone') >= 0){
                if(!validatePhone(element.value)){
                    element.classList.add('is-invalid')
                }
            }
            if(id.split("_").indexOf('name') >= 0){
                if(!element.value){
                    element.classList.add('is-invalid')
                }
            }

            if(id === 'terms_accepted'){
                if(!element.checked){
                    element.classList.add('bg-danger')
                }
            }

            if(id === 'message'){
                if(!element.value){
                    element.classList.add('is-invalid')
                }
            }
        })

        //Check empty fields
        let validated = this.fileds_to_be_validated.filter(f => {
            let ele = document.getElementById(f)
            return ele.value === ''
        });
        return validated.length === 0 && this.terms_accepted;
    }

    removeErrorIndicator(event) {
        let ele_id = event.target.id;
        if (
            this.fields_with_errors.length > 0 &&
            ele_id &&
            this.fields_with_errors.indexOf(ele_id) >= 0
        ) {
            let current_element = document.getElementById(ele_id);
            if (current_element) {
                current_element.classList.remove("is-invalid");
                current_element.classList.remove("bg-danger");
            }
        }
    }
}
