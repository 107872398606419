// javascript/controllers/e_sport_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        let all_services = document.querySelectorAll('.selected_service_section')
        let main_bg = Array.from(document.querySelectorAll('.big-box__img'))
        let previous_id = sessionStorage.getItem('selected_service_id')
        Array.from(all_services).forEach((service, index) => {
            if(!previous_id && index === 0){
                main_bg[index].classList.add('bg-gray987')
                service.classList.remove('d-none')
            }else if(service.id === previous_id){
                service.classList.remove('d-none')
                main_bg[index].classList.add('bg-gray87')
            }else {
                service.classList.add('d-none')
                main_bg[index].classList.remove('bg-gray87')
            }
        })

        let nav = document.getElementById("navigation");
        if(nav){
            nav.classList.add('shadow-sm')
        }
    }

    updateSectionView(e){
        e.preventDefault();
        let element = e.target
        let id = element.dataset.service
        let selected_service = document.getElementById(id)
        let main_bg = Array.from(document.querySelectorAll('.big-box__img'))
        let all_services = document.querySelectorAll('.selected_service_section')
        if(selected_service){
            Array.from(all_services).forEach((service, index) => {
                if(service.id === id){
                    service.classList.remove('d-none')
                    main_bg[index].classList.add('bg-gray87')
                }else {
                    service.classList.add('d-none')
                    main_bg[index].classList.remove('bg-gray87')
                }
            })
            sessionStorage.setItem('selected_service_id', id);
            selected_service.scrollIntoView();
        }
    }
}
