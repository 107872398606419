// javascript/controllers/paytrail_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["paymentForm"];
  connect() {
    this.submitData();
  }

  submitData() {
    let form = this.paymentFormTarget;
    let inputs = form.elements;
    let invalid_fields = Array.from(inputs).filter((valid) => !valid.value);
    if (invalid_fields.length) {
      console.log("invalid");
    } else {
      form.submit();
    }
  }
}
