// javascript/controllers/financing_calculator_controller.js
import {Controller} from "stimulus";
import {calculateLoan} from "../helpers";

export default class extends Controller {

    loan_data = {
        available_months: null,
        price: 0,
        interest: 0,
        interval: null,
        min: 0,
        max: 0,
        handling: 12,
        base: 350
    };

    connect() {
        this.setInitialValues();
    }

    setInitialValues() {
        let loan_initial_values = JSON.parse(this.element.dataset.loan);
        if(loan_initial_values) {
            let {price, interest} = loan_initial_values;
            if(!price) {
                price = 35000
            }
            if(!interest) {
                interest = 6.9
            }
            let pPrice = parseInt(price);
            let max = Math.floor(( pPrice / 2) / 100.0) * 100
            let interval = max >= 1000 ? 100 : 10
            this.loan_data.price = pPrice;
            this.loan_data.interest = parseFloat(interest);
            this.loan_data.max = max;
            this.loan_data.interval = interval;
            this.loan_data.last_payment = Math.floor(pPrice * 0.1);
            this.loan_data.last_payment_max = Math.floor(pPrice * 0.40);
            this.loan_data.months = 72;
            this.loan_data.deposit = 0;
        }
        this.setMaxRangeValues();
        this.updateDataFields();
    }

    getCalculatedLoan() {
        let deposit = this.loan_data.deposit;
        let last_payment = this.loan_data.last_payment;
        let months = this.loan_data.months;
        if(deposit > this.loan_data.max) {
            deposit = this.loan_data.max;
        }
        if(last_payment > this.loan_data.last_payment_max) {
            last_payment = this.loan_data.last_payment_max;
        }
        if(months > 72) {
            months = 72;
        }
        if(months < 12) {
            months = 12;
        }
       return calculateLoan(this.loan_data, last_payment, months, deposit, "not percentage");
    }

    syncCalculatorData(event) {
        let {name, value} = event.target;
        let newValue = parseInt(value);
        if(value && !isNaN(newValue)) {
            if(name === "price" && newValue > 0){
                this.loan_data.max = Math.floor((newValue / 2) / 100.0) * 100;
                this.loan_data.last_payment = Math.floor(newValue * 0.1);
                this.loan_data.last_payment_max = Math.floor(newValue * 0.40);
                this.setMaxRangeValues();
            }
            this.loan_data[name] = value;
            this.updateDataFields();
        }
    }

    updateDataFields() {
        let container = this.element
        let inputs = container.getElementsByTagName("input");
        let totalValue = container.querySelector(".financing-calculator-total")
        for(let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            let name = input.name;
            let value = this.loan_data[name];
            input.value = value;
            let valContent = document.querySelector(`.${name}_value`);
            if(valContent) {
                valContent.textContent = value;
            }
        }
        totalValue.textContent = this.getCalculatedLoan();
    }

    setMaxRangeValues(){
        ["deposit", "last_payment"].forEach((name) => {
            let input = this.element.querySelectorAll(`input[name="${name}"]`);
            input.forEach((el) => {
                if(el) {
                    if(name === "deposit"){
                        el.max = this.loan_data.max;
                    }else if(name === "last_payment"){
                        el.max = this.loan_data.last_payment_max;
                    }
                }
            });
        })
    }
}