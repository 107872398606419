// javascript/controllers/car_presentation_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {currentDateNoTime, getSearchDataFromStorage, saveSearchDataToStorage,} from "../../helpers";

export default class extends Controller {
  static targets = ["downloadCSV", "showResult"];

  dep_date_values = {
    department: "KAIKKI",
    selected_date: currentDateNoTime(),
  };

  car_presentation = {
    per_page: 10,
    search: "",
    category_filter: "KAIKKI",
    start_date: currentDateNoTime(),
    end_date: null,
  };

  path = null;

  connect() {
    this.path = location.pathname.split("/");
    if (this.path.indexOf("new") >= 0 || this.path.indexOf("edit") >= 0) {
      this.getAvailableDates();
    }

    this.updateInputs();
  }

  create_search_params(params) {
    let valid_params = Object.keys(params).filter((key) => params[key]);
    let query = valid_params.reduce((acc, cur) => {
      return acc + `${cur}=${params[cur]}&`;
    }, "");
    return query;
  }

  getSearchData(event) {
    let element = event.target;
    let value = element.value;
    let param = element.dataset.searchValue;
    this.car_presentation[param] = value;
    this.updateData();
  }

  updateData() {
    let search_params = this.create_search_params(this.car_presentation);
    saveSearchDataToStorage("car_presentation", this.car_presentation);
    Rails.ajax({
      type: "get",
      url: `/admin/car_presentations?${search_params}`,
    });
  }

  updateInputs() {
    let inputs = [
      "per_page",
      "search",
      "start_date",
      "end_date",
      "category_filter",
    ];
    let current_values = getSearchDataFromStorage("car_presentation");
    if (current_values) {
      this.car_presentation = current_values;
      inputs.forEach((input) => {
        let element = document.getElementById(`car_presentation_${input}`);
        if (element) {
          element.value = current_values[input];
        }
      });
    }
  }

  clearSearchData() {
    this.car_presentation = {
      per_page: 10,
      search: "",
      category_filter: "KAIKKI",
      start_date: null,
      end_date: null,
    };
    this.updateData();
    this.updateInputs();
  }

  selectAllRows(event) {
    let main_checkbox = event.target;
    let is_checked = main_checkbox.checked;
    let checkboxes = document.querySelectorAll(".selectable-checks");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = is_checked;
    });
  }

  exportAsCSV(event) {
    event.preventDefault();
    let checkboxes = document.querySelectorAll(".selectable-checks");
    let ids = [];
    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          ids.push(checkbox.value);
        }
      });
    }

    if (ids.length > 0) {
      location.href = `${location.href}.csv?presentation_id=${ids}`;
    }
  }

  searchByReg() {
    let input = document.getElementById("presentation_registration");
    let result_field = this.showResultTarget;
    if (input.value) {
      Rails.ajax({
        url: `/admin/cars/find_by_reg/${input.value}`,
        type: "get",
        success: (response) => {
          let { status, car } = response;
          if (status === "SUCCESS") {
            let presentation_car_id = document.getElementById(
              "car_presentation_car_id"
            );
            if (presentation_car_id) {
              presentation_car_id.value = car.id;
              result_field.textContent = `${car.reg_num}, ${car.make} ${car.model}`;
              result_field.classList.add("text-success");
            }
          }
        },
        error: (response) => {
          result_field.textContent = `${input.value} on virheellinen`;
          result_field.classList.add("text-danger");
        },
      });
    } else {
      result_field.textContent = "Error.";
      result_field.classList.add("text-danger");
    }
  }

  getSelectedValues(event) {
    let input = event.target;
    let id = input.id;
    if (id && id === "car_presentation_department") {
      this.dep_date_values["department"] = input.value;
    }

    if (id && id === "car_presentation_selected_date") {
      this.dep_date_values["selected_date"] = input.value;
    }

    this.getAvailableDates();
  }

  getAvailableDates() {
    let department = document.getElementById("car_presentation_department")
      .value;
    let selected_date = document.getElementById(
      "car_presentation_selected_date"
    ).value;

    Rails.ajax({
      url: `/admin/car_presentations/get_available_time?department=${department}&selected_date=${selected_date}`,
      type: "get",
      success: (response) => {
        let { status, data } = response;
        if (status === "SUCCESS") {
          let select = "";
          if (this.path.indexOf("edit") >= 0) {
            data.unshift("Valitse aika");
            select = document.getElementById(
              "car_presentation_selected_time_select"
            );
          } else {
            select = document.getElementById("car_presentation_selected_time");
          }
          select.innerHTML = "";
          data.forEach((date, index) => {
            var opt = document.createElement("option");
            opt.value = date;
            opt.innerHTML = date;
            select.appendChild(opt);
          });
        }
      },
      error: (response) => {
        console.log({ response });
      },
    });
  }

  updateSelectedTime(event) {
    let element = event.target;
    let value = element.value;
    let selected_time = document.getElementById(
      "car_presentation_selected_time"
    );
    if (selected_time) {
      if (value === "Valitse aika") {
        selected_time.value = selected_time.dataset.currentTime;
      } else {
        selected_time.value = value;
      }
    }
  }
}
