// javascript/controllers/sell_request_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {enableDisableFormFields} from "../helpers/index";

export default class extends Controller {
  static targets = ["sellRequestForm"];
  fields_with_errors = [];

  submitSellRequest(event) {
    event.preventDefault();
    let form_url = this.sellRequestFormTarget.action;
    let form_data = new FormData(this.sellRequestFormTarget);
    enableDisableFormFields(this.sellRequestFormTarget, "disable");
    Rails.ajax({
      type: "post",
      url: form_url,
      data: form_data,
      success: (response) => {
        let { status, data } = response;
        if (status === "SUCCESS") {
          let container = document.getElementById("sell-request-modal-body");
          container.innerHTML = `${data}<button type="button" class="btn btn-secondary mt-3" data-bs-dismiss="modal">Sulje</button></div>`;
        }
      },
      error: (response) => {
        enableDisableFormFields(this.sellRequestFormTarget, "enable");
        let error_fields = Object.keys(response); //.map(
        //  (key) => `sell_request_${key}`
        //);
        error_fields.forEach((field_name) => {
          let ele = document.getElementById(field_name);
          if (ele) {
            ele.classList.add("is-invalid");
          }
        });
        this.fields_with_errors = error_fields;
        if (error_fields.length > 0) {
          let first_element = document.getElementById(error_fields[0]);
          first_element.scrollIntoView();
        }
      },
    });
  }

  removeErrorIndicator(event) {
    let ele_id = event.target.id;
    if (
      this.fields_with_errors.length > 0 &&
      ele_id &&
      this.fields_with_errors.indexOf(ele_id) >= 0
    ) {
      let current_element = document.getElementById(ele_id);
      if (current_element) {
        current_element.classList.remove("is-invalid");
      }
    }
  }

  showImportedMessage(event) {
    let submit_btn = document.getElementById(
      "sell-request-submit-btn-container"
    );
    let message = document.getElementById("imported_car_message");
    let yes_checkbox = document.getElementById("imported_car_yes");
    let no_checkbox = document.getElementById("imported_car_no");
    let value = event.target.value;
    if (value === "yes") {
      submit_btn.classList.add("d-none");
      message.classList.remove("d-none");
      if (no_checkbox.checked) {
        no_checkbox.checked = false;
      }
    } else {
      submit_btn.classList.remove("d-none");
      message.classList.add("d-none");
      if (yes_checkbox.checked) {
        yes_checkbox.checked = false;
      }
    }
  }
}
