// javascript/controllers/contact_page_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

    updateProfileModal(e){
        let controller = this;
        let element = e.target
        let modalBtn = element.nextElementSibling;
        let id = element.dataset.elementId;
        let modalBody = document.getElementById('contactProfileModalBody');
        if(modalBtn){
            modalBody.innerHTML =
                `<div class="w-100 height-240 d-flex align-items-center justify-content-center">
                    <div class="box-size-50">
                        <div class="bg-image-loader" style="z-index: 10;"></div>
                    </div>
                </div>`;
            modalBtn.click();
            Rails.ajax({
                url: `/api/elements/${id}`,
                type: 'get',
                success: (response) => {
                    let profile_data = {}
                    let {ingredients, nested_elements } = response;
                    if(nested_elements && nested_elements.length > 0){
                        let extra_profile_data = nested_elements[0]["ingredients"]
                        let ele_data = [...ingredients, ...extra_profile_data]
                        ele_data.forEach(d => {
                            profile_data[d["name"]] = d["value"];
                        })
                    }
                    modalBody.innerHTML =
                        `<div class="w-100">
                            <h3 class="fs-22 fw-semi-bold mb-32-px">${profile_data["name"]}</h3>
                           <div style="border-bottom: 1px solid #1010101A;">
                                <p>${profile_data["about"]}</p>
                           </div>
                            <div class="row" style="border-bottom: 1px solid #1010101A;">
                                <div class="col-sm-6 pt-3">
                                    ${profile_data["title"] ? '<p><span class="fw-semi-bold d-block">Titteli</span><span class="d-block">' + profile_data["title"] + '</span></p>' : ''}
                                    ${profile_data["phone"] ? '<p><span class="fw-semi-bold d-block">Puhelin</span><span class="d-block">' + profile_data["phone"] + '</span></p>' : ''}
                                    ${profile_data["email"] ? '<p><span class="fw-semi-bold d-block">Sähköposti</span><span class="d-block">' + profile_data["email"] + '</span></p>' : ''}
                                    ${profile_data["languages"] ? '<p><span class="fw-semi-bold d-block">Kielitaito</span><span class="d-block">' + profile_data["languages"] + '</span></p>' : ''}
                                </div>
                                <div class="col-sm-6 pt-3">
                                ${profile_data["hometown"] ? '<p><span class="fw-semi-bold d-block">Kotipaikka</span><span class="d-block">' + profile_data["hometown"] + '</span></p>' : ''}
                                ${profile_data["date_of_birth"] ? '<p><span class="fw-semi-bold d-block">Syntymäpäivä</span><span class="d-block">' + profile_data["date_of_birth"] + '</span></p>' : ''}
                                ${profile_data["hobby"] ? '<p><span class="fw-semi-bold d-block">Harrastukset</span><span class="d-block">' + profile_data["hobby"] + '</span></p>' : ''}
                                </div>
                            </div>
                            ${profile_data["profile_video_type"] ?
                            '<div class="w-100 position-relative mt-3" style="height: 50vw; max-height: 500px;">' +
                                controller.createVideoSection(profile_data["profile_video_type"], profile_data["profile_video_source"])
                             + '</div>'
                            : ''
                            }
                        </div>`;
                },
                error: (e) => {
                   console.log(e)
                }
            })
        }
    }

    createVideoSection(video_type, video_link){
        if(video_type === 'vimeo')
            return `<iframe
                          src="https://player.vimeo.com/video/${video_link}?title=0&byline=0&texttrack=fi&dnt=true"
                          style="position:absolute;top:0;left:0;width:100%;height:100%;border-radius: 12px;"
                          class="rounded-12"
                          frameborder="0"
                          allow="autoplay; fullscreen"
                          allowfullscreen
                        ></iframe>
                        <script
                          type="application/javascript"
                          src="https://player.vimeo.com/api/player.js"
                        ></script>`;
        else if(video_type === 'youtube')
            return `<iframe
                          src="${this.generateVideoLink(video_link)}?autoplay=0&mute=1&loop=1"
                          style="position:absolute;top:0;left:0;width:100%;height:100%;"
                          class="rounded-12"
                          frameborder="0"
                          allow="autoplay; fullscreen"
                          allowfullscreen
                        ></iframe>`;
        else if(video_type === '3d')
            return `<iframe
                src="${video_link}"
                class="profile-image-frame"
              ></iframe>`;
    }

    generateVideoLink(link){
        let embed_code = ""
        let index = link.indexOf('=');
        if(index){
            let code = link.substring(index + 1, link.length)
            embed_code = `https://www.youtube.com/embed/${code}`
        }

        return embed_code;
    }
}
