// javascript/controllers/admin_car_promotions_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["downloadCSV", "showResult"];

    connect() {
        let search = document.getElementById('main-search__filters');
        if(search){
            search.classList.remove('start-0');
            search.classList.remove('top-0');
            search.style.top = "80px";
            search.style.fontSize = "16px !important";
            search.style.height = "80%";
            if(window.innerWidth < 1000){
                search.style.left = "45px";
            }else {
                search.style.left = "160px";
            }
        }
    }
}
