// javascript/controllers/maxus_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {validateEmail} from "../helpers";

export default class extends Controller {
  activeDevice = "lg-squared-card-container";
  cardPosition = 0;
  cardContainers = [];
  configurationSettings = {};
  summaryKeys = { price: "Hinta", color: "Väri" };
  sectionFormData = {};
  cardsBuyingPrice = {};
  cardsColorPrice = {};
  cardsSelectablePrice = {};

  connect() {
    this.showHideCardGroups(window.innerWidth);
    let url = location.href;
    let id = url.indexOf("#") > 0 ? url.substring(url.indexOf("#") + 1) : null;
    this.updatePreviousActiveCard(id);

    let nav = document.getElementById("navigation");
    if (nav) {
      nav.classList.add("shadow-sm");
    }

    this.setAvailableConfigurations();
  }

  updatePreviousActiveCard(id) {
    let previous_id = sessionStorage.getItem("selected_card_id");
    let scroll = false;
    if (id) {
      previous_id = this.getServiceElementId(id.replace("model", "service"));
      scroll = true;
    }

    this.activateCardAndContent(previous_id);

    if (scroll) {
      document.getElementById(previous_id).scrollIntoView();
    }

    this.drawCarouselIndicators();
  }

  updateActiveCards(e) {
    this.showHideCardGroups(window.innerWidth);
    this.updatePreviousActiveCard();
  }

  showHideCardGroups(width) {
    if (width > 768) {
      this.activeDevice = "lg-squared-card-container";
    } else {
      this.activeDevice = "md-squared-card-container";
      let device = document.getElementById(this.activeDevice);
      if(!device) {
        this.activeDevice = "lg-squared-card-container";
      }
    }
  }

  updateSectionView(e) {
    e.preventDefault();
    let element = e.target;
    let id = this.getServiceElementId(element);
    let context = null;
    let selected_service = document.getElementById(id);

    if (selected_service) {
      this.activateCardAndContent(id);
      sessionStorage.setItem("selected_card_id", id);
    }
  }

  getServiceElementId(element) {
    let serviceId = element.dataset.service;
    let modelId = serviceId.replace("service", "model");
    let model = document.getElementById(modelId);
    let inputs = model && model.querySelectorAll("input[type='radio']");
    if (inputs && inputs.length > 0) {
      serviceId = inputs[0].dataset.service;
    }

    return serviceId;
  }

  activateCardAndContent(element_id) {
    let device = document.getElementById(this.activeDevice);
    let cards = device.querySelectorAll(".squared_cards_container__img");
    let card_contents = document.querySelectorAll(".selected_service_section");
    let id = element_id ? element_id : card_contents[0].id;
    let foundElement = document.getElementById(id);
    if(!foundElement) {
      id = card_contents[0].id;
    }
    Array.from(card_contents).forEach((content, index) => {
      if (content.id === id) {
        let sub_section = content.querySelector(".video-items");
        if (sub_section && sub_section.classList.contains("d-none")) {
          sub_section.classList.remove("d-none");
        }
        content.classList.remove("d-none");
      } else {
        content.classList.add("d-none");
      }
    });

    let card = Array.from(cards).filter(
      (card) => card.dataset.service === id
    )[0];
    if(!card) {
      card = Array.from(cards)[0];
    }
    this.handleCardsWithRadioButtons(card, "active");
  }

  handleCardsWithRadioButtons(card, action = "inactive") {
    let device = document.getElementById(this.activeDevice);
    let isRadio = card?.type === "radio";
    let cardId = card.dataset.service;
    let mainModelId = isRadio && this.activeDevice !== "md-squared-card-container"
      ? card.dataset.model
      : cardId.replace("service-", "");
    let parentContainer = device.querySelector(`#model-${mainModelId}`);
    let cards = device.querySelectorAll(".squared_cards_container__img");
    let inputs = parentContainer.querySelectorAll("input[type='radio']");

    this.hideShowCardParent(parentContainer);

    if (isRadio) {
      card.checked = true;
      this.showActiveImageCard(cards, `service-${mainModelId}`, true);
    } else if (!isRadio && inputs.length > 0) {
      inputs[0].checked = true;
      this.showActiveImageCard(cards, cardId, true);
    } else {
      this.showActiveImageCard(cards, cardId);
    }
  }

  showActiveImageCard(cards, cardId, hasInput) {
    cards.forEach((card) => {
      if (card.dataset.service === cardId && card.type !== "radio") {
        card.classList.add("active-card");
      } else {
        if (!hasInput && card.type === "radio") {
          card.checked = false;
        } else {
          card.classList.remove("active-card");
        }
      }
    });
  }

  hideShowCardParent(currentCard) {
    let device = document.getElementById(this.activeDevice);
    let page_type = device.dataset.pageType;
    let allParents = device.querySelectorAll('[id^="model-"]');
    if (page_type && page_type === "prevContent"){
      device.style.visibility = "visible";
      allParents.forEach((parent) => {
        parent.classList.remove("max-md-hide");
      });
    }
    else {
      currentCard.classList.remove("max-md-hide");
      allParents.forEach((parent) => {
        if (parent.id !== currentCard.id) {
          parent.classList.add("max-md-hide");
        }
      });
    }
  }

  showActiveImageCard_xxx(cards, cardId, hasInput) {
    cards.forEach((card) => {
      if (card.dataset.service === cardId && card.type !== "radio") {
        card.classList.add("border", "border-2", "border-primary");
      } else {
        if (!hasInput && card.type === "radio") {
          card.checked = false;
        } else {
          card.classList.remove("border", "border-2", "border-primary");
        }
      }
    });
  }

  scrollLeft() {
    this.cardPosition -= 1;
    this.handleCardTransition("left");
  }

  scrollRight() {
    this.cardPosition += 1;
    this.handleCardTransition("right");
  }

  handleCardTransition(direction) {
    let device = document.getElementById(this.activeDevice);
    let cards = device.querySelectorAll(".squared_cards_container__card");
    this.cardPosition = this.availablePositions(
      this.cardPosition,
      cards.length - 1
    );

    let translatePrevious =
      direction === "right" ? "translateX(-200px)" : "translateX(200px)";
    let translateNext =
      direction === "right" ? "translateX(-200px)" : "translateX(200px)";

    cards.forEach((card, index) => {
      let img = card.querySelector("img");
      img.style.transitionDuration = "0.4s";
      if (card.classList.contains("max-md-hide")) {
        img.style.transform = translatePrevious;
      } else {
        img.style.transform = translateNext;
      }
    });

    setTimeout(() => {
      cards.forEach((card, index) => {
        let img = card.querySelector("img");
        let btn = document.getElementById(`btn_${card.id}`);
        if (index === this.cardPosition) {
          card.classList.remove("max-md-hide");
          img.style.transform = `translateX(0)`;
          btn.classList.add("bg-primary");
          btn.classList.remove("max-inactive-indicator");
          let service_card = card.firstElementChild
          if(service_card) {
            service_card.click()
          }
        } else {
          card.classList.add("max-md-hide");
          img.style.transform = `translateX(0)`;
          btn.classList.remove("bg-primary");
          btn.classList.add("max-inactive-indicator");
        }
      });
    }, 400);

    setTimeout(() => {
      let img = cards[this.cardPosition].querySelector("img");
      img.style.transform = `translateX(0)`;
    }, 800);
  }

  drawCarouselIndicators() {
    //<button class="btn btn-primary box-size-5 rounded-circle p-0 mx-1 bg-hover-primary"></button>
    let device = document.getElementById("md-squared-card-container");
    if (device) {
      let cards = device.querySelectorAll(".squared_cards_container__card");
      let indicatorsContainer = device.querySelector(
        ".cards-carousel-indicator"
      );
      let indicators = "";
      cards.forEach((card, index) => {
        indicators += `<button id="btn_${card.id}" class="${
          card.classList.contains("max-md-hide")
            ? "max-inactive-indicator"
            : "bg-primary"
        } btn box-size-5 rounded-circle p-0 mx-1"></button>`;
      });

      indicatorsContainer.innerHTML = indicators;
    }
  }

  availablePositions(current, total) {
    let curr = current;
    if (curr < 0) {
      curr = total;
    } else if (curr > total) {
      curr = 0;
    }

    return curr;
  }

  setAvailableConfigurations() {
    let card_contents = document.querySelectorAll(".selected_service_section");
    if (card_contents && card_contents.length > 0) {
      card_contents.forEach((card_content) => {
        this.configurationSettings[card_content.id] = {};
        this.sectionFormData[card_content.id] = {};
        this.setSummaryPrice(card_content);
        this.setSummaryColor(card_content);
        this.setAllSummaryOptions(card_content);
      });
    }
  }
  setSummaryPrice(card) {
    let priceOptions = card.querySelectorAll(
      ".maxus_lease_buy_options_content"
    );
    let priceDescription = "";
    let activePriceOptions = Array.from(priceOptions).filter((priceOption, index) =>
      index === 0
    );
    if(activePriceOptions.length > 0) {
      let price = activePriceOptions[0].dataset.price;
      if(price) {
        let buyingPrice = parseInt(price.replace(/[^0-9]+/g, ""), 10) || 0;
        this.cardsBuyingPrice[card.id] = buyingPrice;
      }
    }

  }
  setSummaryColor(card) {
    let colorOptions = card.querySelectorAll(".maxus_color_options_content");
    let colorDescription = "";
    colorOptions.forEach((colorOption, index) => {
      if (colorOption.classList.contains("active")) {
        colorDescription += `${colorOption.dataset.color}`;
        let colorPrice = parseInt(colorOption.dataset.price.replace(/[^0-9]+/g, ""), 10) || 0;
        this.cardsColorPrice[card.id] = colorPrice;
      }
    });
    this.configurationSettings[card.id]["color"] = colorDescription;
    this.setAllSummaryOptions(card);
  }
  updateFormSummary(e) {
    let elem = e.target;
    let type = elem.type;
  }
  buyLeaseOptions(e) {
    let elem = e.target;
    elem.classList.toggle("is-set");
    let card = elem.closest(".selected_service_section");
    this.setSummaryPrice(card);
    this.setAllSummaryOptions(card);
  }
  colorOptions(e) {
    let elem = e.target;
    let index = elem.dataset.index;
    let card = elem.closest(".selected_service_section");
    let allColors = card.querySelectorAll(".maxus_color_options_content");
    let allColorsText = card.querySelectorAll(
      ".maxus_color_options_content-price"
    );
    allColors.forEach((color) => {
      if (color.dataset.index === index) {
        color.classList.add("active");
      } else {
        color.classList.remove("active");
      }
    });
    allColorsText.forEach((colorText) => {
      if (colorText.dataset.index === index) {
        colorText.classList.remove("d-none");
      } else {
        colorText.classList.add("d-none");
      }
    });

    this.setSummaryColor(card);
  }
  selectableOptions(e) {
    let elem = e.target;
    let name = elem.dataset.summary;
    let checked = elem.checked;
    let value = elem.value;
    let price = elem.dataset.price;
    let card = elem.closest(".selected_service_section");
    if (card) {
      let activeSelections = this.configurationSettings[card.id][name];
      let currentCard = this.cardsSelectablePrice[card.id] || {};
      if (activeSelections) {
        if (checked && !activeSelections.includes(value)) {
          activeSelections.push(value);
          this.cardsSelectablePrice[card.id] = {...currentCard, [value]: parseInt(price, 10)}
        } else if (!checked && activeSelections.includes(value)) {
          activeSelections = activeSelections.filter(
            (selection) => selection !== value
          );
          this.cardsSelectablePrice[card.id] = {...currentCard, [value]: null}
        }
        this.configurationSettings[card.id][name] = activeSelections;
      } else {
        this.configurationSettings[card.id][name] = [value];
        this.cardsSelectablePrice[card.id] = {...currentCard, [value]: parseInt(price, 10)}
      }
    }
    this.setAllSummaryOptions(card);
  }
  setAllSummaryOptions(card) {
    let summaryContent = card.querySelector(".summary-content");
    if (summaryContent) {
      let html = "";
      let message = "";
      Object.keys(this.configurationSettings[card.id]).forEach((key) => {
        let value = this.configurationSettings[card.id][key];
        if (typeof value === "object") {
          value = value.join(", ");
        }
        html += `<p class="d-block mb-0 lh-28 ls-035 fs-16"><span class="fw-bold">${
          this.summaryKeys[key] ? this.summaryKeys[key] : key
        }:</span> ${value}</p>`;
        if (value) {
          message += `${
            this.summaryKeys[key] ? this.summaryKeys[key] : key
          }: ${value}\n. `;
        }
      });
      let summary = 0;
      if(this.cardsSelectablePrice[card.id]) {
        Object.keys(this.cardsSelectablePrice[card.id]).forEach((key) => {
          if(this.cardsSelectablePrice[card.id][key]) {
            summary += this.cardsSelectablePrice[card.id][key]
          }
        })
      }
      summary += this.cardsBuyingPrice[card.id] + this.cardsColorPrice[card.id];
      html += `<p class="d-block mt-3 fs-22 lh-28 ls-048"><span class="fw-bold">Kokoonpanon hinta yhteensä:</span><span style="white-space: nowrap"> ${parseInt(summary, 10) + 600 + 310} €</span></p>`;
      html += `<div class="d-block mb-0 lh-28 ls-035 fs-16">
                <span class="fw-bold">Sisältää: </span>
                <ul>
                    <li>Toimituskulut 600 €</li>
                    <li>Latauskaapeli 310 €</li>
                </ul>
              </div>`;
      summaryContent.innerHTML = html;
      this.sectionFormData[card.id]["message"] = message;
    }
  }
  updateFormData(e) {
    let elem = e.target;
    let name = elem.name;
    let value =
      elem.type === "checkbox"
        ? this.getCheckedValues(name, elem.checked)
        : elem.value;
    let card = elem.closest(".selected_service_section");
    if (card) {
      if(name === "exchange_car") {
        let exchange_car = card.querySelector(".exchange_car_fields");
        if(exchange_car){
          if(elem.checked) exchange_car.classList.remove('d-none');
          else exchange_car.classList.add('d-none');
        }
      }
      if (value) {
        this.sectionFormData[card.id][name] = value;
      }
      this.showErrors(null, card, "hide");
    }
  }
  getCheckedValues(name, checked) {
    return checked && name === "financing_offer"
      ? "Haluan autolle rahoitustarjouksen. "
      : checked;
  }
  submitFormData(e) {
    let elem = e.target;
    elem.disabled = true;
    let card = elem.closest(".selected_service_section");
    let formData = this.sectionFormData[card.id];
    formData["message"] = `${
      formData["financing_offer"] ? formData["financing_offer"] : ""
    }\n${formData["message"]}`;
    this.validateAndSendForm(card, formData, elem);
  }

  validateAndSendForm(card, formData, submitBtn) {
    let errors = "";
    if (formData["exchange_car"] && !formData["reg_num"] && !formData["km"]) {
      errors = `<li>Ole hyvä ja lisää vaihtoauton tiedot</li>`;
      this.showErrors(errors, card, "show");
      submitBtn.disabled = false;
      return;
    }
    if (
      !formData["first_name"] ||
      !validateEmail(formData["email"]) ||
      !formData["phone"]
    ) {
      errors = `<li>Ole hyvä ja lisää yhteystietosi</li>`;
      this.showErrors(errors, card, "show");
      submitBtn.disabled = false;
      return;
    }
    if (!formData["consent"]) {
      errors = `<li>Ole hyvä ja hyväksy käyttöehdot</li>`;
      this.showErrors(errors, card, "show");
      submitBtn.disabled = false;
      return;
    }

    let fd = new FormData();
    let updatedFormData = {
      ...formData,
      message: `Malli: ${card.dataset.make}. ${formData["message"]}`,
      category: "MAXUS LASKURI",
      location: location.href,
    };
    Object.keys(updatedFormData).forEach((key) => {
      fd.append(key, updatedFormData[key]);
    });

    let controller = this;

    Rails.ajax({
      url: "/contact_requests",
      type: "POST",
      data: fd,
      success: function (response) {
        let { status } = response;
        if (status === "error") {
          errors = `<li>Virhe lomakkeen lähettämisessä.</li>
          <li>Tarkista, onko kaikki kentät täytetty oikein.</li>`;
          controller.showErrors(errors, card, "show");
          submitBtn.disabled = false;
        } else if (status === "created") {
          errors = `<li class="text-success">Kiitos. Lähetys onnistui!. Myyjämme ottaa sinuun pian yhteyttä</li>`;
          controller.showErrors(errors, card, "show");
          let inputs = card.querySelectorAll("input");
          inputs.forEach((input) => {
            input.type === "checkbox"
              ? (input.checked = false)
              : (input.value = "");
          });
        }
      },
      error: function (response) {
        errors = `<li>Virhe lomakkeen lähettämisessä</li>
        <li>Tarkista, onko kaikki kentät täytetty oikein.</li>`;
        controller.showErrors(errors, card, "show");
        submitBtn.disabled = false;
      },
    });
  }

  showErrors(errors = null, card, action) {
    let errorsList = card.querySelectorAll(".errors-list");
    errorsList.forEach((errorList) => {
      if (action === "show") {
        errorList.innerHTML = errors;
      } else {
        errorList.innerHTML = "";
      }
    });
  }

  showExtraInfoSection(e){
    let label = e.target.closest("label");
    if(label){
      let extraInfo = label.nextElementSibling
      if(extraInfo){
        extraInfo.classList.toggle("d-none");
      }
    }
  }
}
