// javascript/controllers/import_cars_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

    connect() {
        this.getVehicle();
    }

    getVehicle() {
        let controller = this;
        let container = document.getElementById("car-page-container");
        let slug = window.location.pathname.split("/").pop();
        Rails.ajax({
            url: "/tuontiautot/get_vehicle?id=" + slug,
            type: "GET",
            success: (response) => {
                const {status, data } = response;
                container.innerHTML = data;
            },
            error: (error) => {
                console.log("Error: ", error);
            }
        });
    }
}