import {Controller} from "stimulus";

export default class extends Controller {
    hideAccVideo(e){
        let accModal = document.getElementById('accVideoModal');
        let modalBody = accModal.querySelector('.modal-body');
        if(modalBody){
            modalBody.innerHTML = '';
        }
    }
}