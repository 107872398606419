// javascript/controllers/car_usage_expence_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["carExpence", "calculator"];

    connect() {
        try {
            let mainElement = this.carExpenceTarget || null;
            if(mainElement) {
                this.updateAllCalculations(mainElement);
            }
        }catch (e) {
            console.log(e);
        }

        try {
            setTimeout(() => {
                let expenseCalculator = document.querySelectorAll(".expense-calculator")
                if(expenseCalculator && expenseCalculator.length > 0) {
                    sessionStorage.setItem("expenseCalculator", JSON.stringify(expenseCalculator.length));
                    expenseCalculator.forEach(calculator => {
                        let formData = JSON.parse(calculator.dataset.expence);
                        this.getFieldsAndUpdateByName(formData, true);
                    })
                    if(window.innerWidth < 575) {
                        this.cardsObserver();
                    }
                }
            }, 250);
        }catch (e) {
            console.log(e);
        }

    }

    updateAllCalculations(mainElement) {
        // Get all form elements
        let formElements = mainElement.querySelectorAll("input, select");
        let formData = {};
        // Loop through all form elements
        for (let i = 0; i < formElements.length; i++) {
            // Get the element
            let element = formElements[i];
            // Get the element's name
            let name = element.getAttribute("name");
            // Get the element's value
            let value = element.value;
            // Update the element's value in the form
            let field = name.replace("car_usage_expence[", "").replace("]", "");
            let fieldElement = null;
            if(!field.startsWith("link_info")){
                fieldElement = mainElement.querySelector(`#${field}_value`);
            }
            if(fieldElement) {
                let parsedValue = parseFloat(this.parseValueToFloat(value)).toLocaleString("fi-FI");
                if(parsedValue) {
                    fieldElement.textContent = `${parsedValue}`;
                }else {
                    fieldElement.textContent = `0`;
                }
            }
            formData[field] = value;
        }
        this.getFieldsAndUpdateByName(formData);
    }

    getFieldsAndUpdateByName(formData, has_uuid_id = false) {
        let holding_period = 10;// parseInt(formData.holding_period, 10);
        let car_price = parseFloat(formData.car_price);
        let fuel_price = parseFloat(formData.fuel_price);
        let petrol_price = parseFloat(formData.petrol_price);
        let diesel_price = parseFloat(formData.diesel_price);
        let electricity_price = parseFloat(formData.electricity_price);
        let fuel_tax = parseFloat(formData.fuel_tax);
        let basic_tax = parseFloat(formData.basic_tax);
        let fuel_type = formData.fuel_type;
        let driving_range = parseFloat(formData.driving_range);
        let electric_driving_percentage = parseFloat(formData.electric_driving_percentage) / 100;
        let fossil_driving_percentage = parseFloat(formData.fossil_driving_percentage) / 100;
        let consumption_full_electric = parseFloat(formData.consumption_full_electric);
        let consumption_full_fossil = parseFloat(formData.consumption_full_fossil);
        let insurance_yearly = parseFloat(formData.insurance_yearly);
        let services_yearly = parseFloat(formData.services_yearly);
        let monthly_installment = formData.monthly_installment ? parseFloat(formData.monthly_installment) : 350;
        let remaining_value_percentage = this.yearly_depreciation(holding_period);
        let remaining_value = car_price * (remaining_value_percentage / 100);
        let average_monthly_depreciation = (car_price - remaining_value) / (12 * holding_period);

        let monthly_vehicle_tax = (fuel_tax + basic_tax) / 12;
        let monthly_insurance = insurance_yearly / 12;
        let monthly_services = services_yearly / 12;
        let consumption_monthly_petrol = driving_range / 100 * consumption_full_fossil * petrol_price / 12;
        let consumption_monthly_hybrid = driving_range / 100 * (fossil_driving_percentage * consumption_full_fossil * petrol_price + electric_driving_percentage * consumption_full_electric * electricity_price) / 12;
        let consumption_monthly_electric = driving_range / 100 * (fossil_driving_percentage * consumption_full_fossil * petrol_price + electric_driving_percentage * consumption_full_electric * electricity_price) / 12;
        let consumption_monthly_diesel = driving_range / 100 * (fossil_driving_percentage * consumption_full_fossil * diesel_price + electric_driving_percentage * consumption_full_electric * electricity_price) / 12;

        let consumption_monthly = {
            petrol: consumption_monthly_petrol,
            plugin_hybrid: consumption_monthly_hybrid,
            electric: consumption_monthly_electric,
            diesel: consumption_monthly_diesel,
        }

        let total_expenses = consumption_monthly[fuel_type] + monthly_services + monthly_insurance + monthly_vehicle_tax + average_monthly_depreciation + monthly_installment;

        const fixedFields = [
            {field: "remaining_value_percentage", value: remaining_value_percentage},
            {field: "remaining_value", value: remaining_value},
            {field: "average_monthly_depreciation", value: average_monthly_depreciation},
            {field: "monthly_vehicle_tax", value: monthly_vehicle_tax},
            {field: "insurance_monthly", value: monthly_insurance},
            {field: "services_monthly", value: monthly_services},
            {field: "consumption_monthly", value: consumption_monthly[fuel_type]},
            {field: "total_expenses", value: total_expenses},
        ];

        fixedFields.forEach(field => {
            let unit = field.field === "remaining_value_percentage" ? "%" : "€";
            let value = parseFloat(this.parseValueToFloat(field.value)).toLocaleString("fi-FI");
            let id = field.field;
            if(has_uuid_id){
                id = `${field.field}_${formData.id}`;
            }
            this.updateFieldById(id, `${value} ${unit}`);
        })
    }

    updateFieldById(id, value) {
        let field = document.getElementById(id);
        if(field && value) {
            field.textContent = value;
        }
    }

    parseValueToFloat(value) {
        let val = parseFloat(value).toFixed(2);
        return val && !isNaN(value) ? val : 0.00;
    }

    updateValueFields(e) {
        let mainElement = this.carExpenceTarget;
        if(mainElement) {
            this.updateAllCalculations(mainElement)
        }
    }

    yearly_depreciation(year){
        switch (parseInt(year, 10)) {
            case 0:
                return 100.00;
            case 1:
                return 85.15;
            case 2:
                return 75.40;
            case 3:
                return 68.20;
            case 4:
                return 59.30;
            case 5:
                return 52.50;
            case 6:
                return 46.60;
            case 7:
                return 41.30;
            case 8:
                return 35.80;
            case 9:
                return 28.10;
            case 10:
                return 22.10;
            default:
                return 100.00;
        }
    }

    showImagePreview(e){
        // Get file and set preview url
        let file = e.target.files[0];
        let src = URL.createObjectURL(file);
        let preview = document.getElementById('image-preview');
        preview.innerHTML = `<img src="${src}" alt="preview" class="img-fluid">`;
    }

    performCalculations(e){
        let target = e.target;
        let id = target.getAttribute("id");
        let name = target.getAttribute("name");
        let value = target.value;
        let calculatorElement = target.closest(".expense-calculator");
        if(calculatorElement) {
            let formData = JSON.parse(calculatorElement.dataset.expence);
            if(parseInt(value, 10) > 0 && !isNaN(value)) {
                formData[name] = value;
            }
            this.getFieldsAndUpdateByName(formData, true);
        }
    }

    cardsObserver(){
        let cols = document.querySelectorAll(".expense-calculator");
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                const { boundingClientRect } = entry;
                let target = entry.target;
                let id = target.getAttribute("id");
                let index = target.getAttribute("data-index");
                let data = JSON.parse(target.dataset.expence);
                let {top, height, bottom} = boundingClientRect;
                if(top < 0 - height) {
                    //target.classList.add("bg-danger");
                    let i = parseInt(index, 10);
                    if(i === 0) {
                        let div = document.createElement("div");
                        div.classList.add("position-fixed", "bg-primary", "text-white", "p-3", "start-0", "end-0");
                        div.style.top = "60px";
                        div.style.zIndex = "999";
                        div.setAttribute("id", `expense-calculator-fixed-${index}`);
                        let totalSum = document.getElementById(`total_expenses_${data.id}`);
                        let title = document.getElementById(`title_${data.id}`);
                        div.innerHTML = `<p class="mb-1">${title.textContent}n kokonaiskulut / kk: ${totalSum.textContent}</p>`;
                        document.body.appendChild(div);
                    }else if (i > 0){
                        let container = document.getElementById(`expense-calculator-fixed-0`);
                        let totalSum = document.getElementById(`total_expenses_${data.id}`);
                        let title = document.getElementById(`title_${data.id}`);
                        let pTag = `<p id="expense-calculator-fixed-${index}" class="mb-1">${title.textContent}n kokonaiskulut / kk: ${totalSum.textContent}</p>`;
                        container.innerHTML += pTag;
                    }
                }else {
                    if(bottom + 75 > height) {
                        //target.classList.remove("bg-danger");
                        let currentDiv = document.getElementById(`expense-calculator-fixed-${index}`);
                        if(currentDiv) {
                            currentDiv.remove();
                        }
                    }
                }
            });
        }, {
            root: null,
            rootMargin: '0px 0px -100%'});

        cols.forEach(col => {
            observer.observe(col);
        })
    }

    toggleMoreInfo(e){
        let elem = e.target;
        let row = elem.closest("tr");
        let isVisible = row.getAttribute("aria-expanded") === "true";
        let id = row.getAttribute("id");
        row.setAttribute("aria-expanded", !isVisible);
        if(isVisible) {
            let p = row.getElementsByTagName('p')[0]
            p.innerHTML = "<i class=\"fas fa-plus text-dark pe-none\"></i>";
        }else {
            let p = row.getElementsByTagName('p')[0]
            p.innerHTML = "<i class=\"fas fa-minus text-dark pe-none\"></i>";
        }
        let rows = document.querySelectorAll(`tr[data-parent="${id}"]`);
        rows.forEach(row => {
            row.classList.toggle("calc-row__more-info");
        })
    }
}
