// javascript/controllers/media_page_controller.js
import {Controller} from "stimulus";

export default class extends Controller {

    connect(){
        this.getNumberOfMediaContents();
    }

    showMoreMediaContent(event) {
        let media_contents_container = document.getElementById('media-contents-container');
        let count = media_contents_container.dataset.visibleMedia;
        let count_to_int = parseInt(count, 10);
        let media_contents = document.querySelectorAll('.media-content-summary');

        if( count_to_int < media_contents.length){
            count_to_int = count_to_int + 5
            media_contents_container.dataset.visibleMedia = count_to_int
            if(media_contents.length < count_to_int){
                media_contents_container.dataset.visibleMedia = media_contents.length
                count_to_int = media_contents.length;
            }
        }
        if(media_contents.length > 0){
            media_contents.forEach((content, index) => {
                //let index = parseInt(content.dataset.summaryId, 0);
                if(index > count_to_int){
                    content.classList.add('d-none')
                }else {
                    if(content.classList.contains('d-none')){
                        content.classList.remove('d-none')
                    }
                }
            })
        }

        if(media_contents.length === count_to_int){
            let show_more_btn = document.getElementById('show-more-media-content-btn');
            show_more_btn.classList.add('d-none');
        }
    }

    getNumberOfMediaContents(){
        let media_contents_container = document.getElementById('media-contents-container');
        let round = media_contents_container.dataset.round;
        if(round === 'initial'){
            let show_more_btn = document.getElementById('show-more-media-content-btn');
            let media_contents = document.querySelectorAll('.media-content-summary');
            media_contents.forEach((content, index) => {
                if(index >= 5){
                    content.classList.add('d-none');
                    media_contents_container.dataset.round = "phase2"
                }
            })

            if(show_more_btn){
                if(media_contents.length < 5){
                    show_more_btn.classList.add('d-none')
                }
            }
        }
    }
}
