// javascript/controllers/custom_form_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {validateEmail} from "../helpers";

export default class extends Controller {
  submitForm(event) {
    event.preventDefault();
    let main_form = event.target.closest("form");
    let custom_checkbox_fields_values = main_form.querySelector(
      "input[name='custom_checkbox_fields_values']"
    );
    let checkboxes = main_form.querySelectorAll(
      "input[name^='custom_checkbox']"
    );
    let checked_checkboxes = Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => `${checkbox.value}: Kyllä`)
      .join(", ");
    if (checked_checkboxes) {
      custom_checkbox_fields_values.value = `\n ${checked_checkboxes}`;
    }
    let form_elements = main_form.elements;
    let invalid_elements = [];

    Array.from(form_elements).forEach((ele) => {
      let validatable = ele.dataset.validate === "true";
      if (validatable) {
        if (!ele.value && ele.name !== "submit") {
          invalid_elements.push(ele.name);
          ele.classList.add("is-invalid");
        }
        if (ele.name === "email") {
          if (!validateEmail(ele.value)) {
            invalid_elements.push(ele.name);
            ele.classList.add("is-invalid");
          }
        }
        if (ele.type === "checkbox" && !ele.checked) {
          invalid_elements.push(ele.name);
          ele.classList.add("is-invalid");
        }
      }
      // Always validate terms accepted checkbox
      if (ele.name === "terms_accepted" && !ele.checked) {
        invalid_elements.push(ele.name);
        ele.classList.add("is-invalid");
      }
    });
    if (invalid_elements.length === 0) {
      Rails.ajax({
        url: "/general-custom-form",
        type: "POST",
        data: new FormData(main_form),
        error: function (response) {
          Object.keys(response).forEach((key) => {
            let error_field = form_elements[key];
            if (error_field) {
              error_field.classList.add("is-invalid");
            }
          });
        },
      });
    }
  }

  validateField(e) {
    e.target.classList.remove("is-invalid");
  }
}
