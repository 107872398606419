// javascript/controllers/lift_up_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {}

  startScroll(e, direction) {
    console.log("scrolling");
    let elem = e.target;
    let container = elem.closest(".promotional_cars_container");
    if (container) {
      let scrollable = container.querySelector(".scrollable-x");
      if (scrollable) {
        let firstChild = scrollable.children[1] || 320;
        if (firstChild) {
          let firstChildWidth = firstChild.offsetWidth;
          scrollable.scrollBy({
            left: direction * (firstChildWidth + 10),
            behavior: "smooth",
          });
        }
      }
    }
  }

  scrollLeft(e) {
    this.startScroll(e, -1);
  }

  scrollRight(e) {
    this.startScroll(e, 1);
  }
}
