// javascript/controllers/admin_contact_request_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {getSearchDataFromStorage, saveSearchDataToStorage} from "../../helpers";

export default class extends Controller {
  static targets = ["downloadCSV"];

  contact_request = {
    per_page: 10,
    search: "",
    category_filter: "CONTACT",
    start_date: null,
    end_date: null,
  };

  connect() {
    this.updateInputs();
  }

  create_search_params(params) {
    let valid_params = Object.keys(params).filter((key) => params[key]);
    let query = valid_params.reduce((acc, cur) => {
      return acc + `${cur}=${params[cur]}&`;
    }, "");
    return query;
  }

  getSearchData(event) {
    let element = event.target;
    let value = element.value;
    let param = element.dataset.searchValue;
    this.contact_request[param] = value;
    this.updateData();
  }

  updateData() {
    let search_params = this.create_search_params(this.contact_request);
    saveSearchDataToStorage("contact_request", this.contact_request);
    Rails.ajax({
      type: "get",
      url: `/admin/contact_requests?${search_params}`,
    });
  }

  updateInputs() {
    let inputs = [
      "per_page",
      "search",
      "start_date",
      "end_date",
      "category_filter",
    ];
    let current_values = getSearchDataFromStorage("contact_request");
    if (current_values) {
      this.contact_request = current_values;
      inputs.forEach((input) => {
        document.getElementById(`contact_request_${input}`).value =
          current_values[input];
      });
    }
  }

  clearSearchData() {
    this.contact_request = {
      per_page: 10,
      search: "",
      category_filter: "CONTACT",
      start_date: null,
      end_date: null,
    };
    this.updateData();
    this.updateInputs();
  }

  selectAllRows(event) {
    let main_checkbox = event.target;
    let is_checked = main_checkbox.checked;
    let checkboxes = document.querySelectorAll(".selectable-checks");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = is_checked;
    });
  }

  singleRowToCSV(event) {
    let value = event.target.value;
    this.downloadCSVTarget.href = `${location.href}.csv?reservation_id=${value}`;
  }

  exportAsCSV(event) {
    event.preventDefault();
    let checkboxes = document.querySelectorAll(".selectable-checks");
    let ids = [];
    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          ids.push(checkbox.value);
        }
      });
    }

    if (ids.length > 0) {
      location.href = `${location.href}.csv?contact_request_id=${ids}`;
    }
  }
}
