// javascript/controllers/carousel_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "cWideContainer",
    "narrowScrollableContainer",
    "narrowCardHolder",
    "narrowLeftArrowContainer",
    "narrowRightArrowContainer",
    "wideCardHolder",
    "wideLeftArrow",
    "wideRightArrow",
  ];

  scroll_step = 100;

  connect() {
    this.activateTableScrollers();
  }

  scrollRight() {
    let container = this.cWideContainerTarget;
    this.scrollContainer("right", container);
    this.wideScrollIndicators();
  }

  scrollLeft() {
    let container = this.cWideContainerTarget;
    this.scrollContainer("left", container);
    this.wideScrollIndicators();
  }

  narrowScrollLeft() {
    let narrow_card_holder = document.getElementById("narrow-card-holder");
    if (narrow_card_holder) {
      let left = narrow_card_holder.style.left;
      if (left === "0px") {
        narrow_card_holder.style.left = `${50}px`;
      }
    }
    let container = this.narrowScrollableContainerTarget;
    this.scrollContainerNarrow("left", container);
    this.narrowScrollIndicators();
  }

  narrowScrollRight() {
    let container = this.narrowScrollableContainerTarget;
    this.scrollContainerNarrow("right", container);
    this.narrowScrollIndicators();
  }

  scrollContainer(direction, container) {
    let scrollBehavior = container.style.scrollBehavior;
    if (scrollBehavior !== "smooth") {
      container.style.scrollBehavior = "smooth";
    }
    let wide_carousel = container.querySelector(".cr-wide__card");
    if (wide_carousel) {
      //Get the width of the card
      let { width } = wide_carousel.getBoundingClientRect();
      //set the scroll step to the width of the card
      this.scroll_step = width;
    }
    if (direction === "left") {
      container.scrollLeft += this.scroll_step;
    }

    if (direction === "right") {
      container.scrollLeft -= this.scroll_step;
    }
  }

  scrollContainerNarrow(direction, container) {
    container.style.scrollBehavior = "smooth";
    if (direction === "left") {
      container.scrollLeft += 300;
    }

    if (direction === "right") {
      container.scrollLeft -= 300;
    }
  }

  wideScrollIndicators() {
    this.showHideIndicators(
      this.wideCardHolderTarget,
      "wide",
      ".cr-wide__card",
      460
    );
  }

  showHideIndicators(parent_element, carousel_type, tag_name, custom_width) {
    let container = parent_element;
    let container_children = container.querySelectorAll(tag_name);
    let first_child = container_children[0];
    let last_child = container_children[container_children.length - 1];
    let { x: first_child_x } = first_child.getBoundingClientRect();
    let { x: last_child_x } = last_child.getBoundingClientRect();
    if (first_child_x > -10) {
      if (carousel_type === "narrow") {
        this.narrowLeftArrowContainerTarget.classList.add("d-none");
      }

      if (carousel_type === "wide") {
        this.wideLeftArrowTarget.classList.remove("cr-wide__scrollers--active");
      }
    } else {
      if (carousel_type === "narrow") {
        this.narrowLeftArrowContainerTarget.classList.remove("d-none");
      }
      if (carousel_type === "wide") {
        this.wideLeftArrowTarget.classList.add("cr-wide__scrollers--active");
      }
    }

    if (this.isLastElementVisible(last_child_x, custom_width)) {
      if (carousel_type === "narrow") {
        this.narrowRightArrowContainerTarget.classList.add("d-none");
      }
      if (carousel_type === "wide") {
        this.wideRightArrowTarget.classList.remove(
          "cr-wide__scrollers--active"
        );
      }
    } else {
      if (carousel_type === "narrow") {
        this.narrowRightArrowContainerTarget.classList.remove("d-none");
      }
      if (carousel_type === "wide") {
        this.wideRightArrowTarget.classList.add("cr-wide__scrollers--active");
      }
    }
  }

  narrowScrollIndicators() {
    this.showHideNarrowIndicators(
      this.narrowCardHolderTarget,
      "narrow",
      "a",
      500
    );
  }

  showHideNarrowIndicators(
    parent_element,
    carousel_type,
    tag_name,
    custom_width
  ) {
    let container = parent_element;
    let container_children = container.querySelectorAll(tag_name);
    let first_child = container_children[0];
    let last_child = container_children[container_children.length - 1];
    let { x: first_child_x } = first_child.getBoundingClientRect();
    let { x: last_child_x } = last_child.getBoundingClientRect();
    if (first_child_x > 260) {
      if (carousel_type === "narrow") {
        //this.narrowLeftArrowContainerTarget.classList.add("d-none");
      }
    } else {
      if (carousel_type === "narrow") {
        this.narrowLeftArrowContainerTarget.classList.remove("d-none");
      }
    }

    if (this.isLastElementVisibleNarrow(last_child_x, custom_width)) {
      if (carousel_type === "narrow") {
        //this.narrowRightArrowContainerTarget.classList.add("d-none");
      }
    } else {
      if (carousel_type === "narrow") {
        //this.narrowRightArrowContainerTarget.classList.remove("d-none");
      }
    }
  }

  isLastElementVisibleNarrow(x, width) {
    return (
      x + width + 60 < window.innerWidth ||
      x + width < document.documentElement.clientWidth
    );
  }

  isLastElementVisible(x, width) {
    return (
      x + width + 30 < window.innerWidth ||
      x + width < document.documentElement.clientWidth
    );
  }

  // Table scrollers
  activateTableScrollers() {
    let table_containers = document.querySelectorAll(
      ".table-responsive-container"
    );
    Array.from(table_containers).forEach((table_container) => {
      let indicators = table_container.querySelector(".table-indicators");
      let table = table_container.querySelector(".car-description-table");
      let { right: container_right } = table_container.getBoundingClientRect();
      let { right: table_right } = table.getBoundingClientRect();

      if (table_right > container_right) {
        indicators.classList.remove("d-none");
      } else {
        indicators.classList.add("d-none");
      }
    });
  }

  scrollTable(e) {
    let element = e.target;
    let direction = element.dataset.direction;
    if (direction) {
      let main_container = element.closest(".table-responsive-container");
      let container = main_container.querySelector(".table-responsive");
      console.log("container ", container);
      if (direction === "left") {
        container.scrollLeft += 150;
      } else if (direction === "right") {
        container.scrollLeft -= 150;
      }
    }
  }

  scrollContactSection(e) {
    let element = e.target;
    let direction = element.dataset.direction;
    if (direction) {
      let main_container = element.closest(".company-contact-container");
      let container = main_container.querySelector(".scrollable-x");
      let cards = container.children.length;
      container.style.scrollBehavior = "smooth";
      if (direction === "left") {
        container.scrollLeft += 360;
      } else if (direction === "right") {
        container.scrollLeft -= 360;
      }
    }
  }

  setElementName(e) {
    let element = e.target;
    let parent = element.closest(".cr-wide__card");
    let main_title = parent.getElementsByTagName("h5")[0];
    let sub_title = parent.getElementsByTagName("h4")[0];
    let title = "";
    if (main_title) {
      title = main_title.innerText;
    }

    if (sub_title) {
      title = `${title}, ${sub_title.innerText}`;
    }

    sessionStorage.setItem("contact_card_title", title);
  }
}
