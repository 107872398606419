// Posting data
export async function postData(url = "", method, data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content"),
    },
    //redirect: 'follow', // manual, *follow, error
    //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (!response.ok) {
    if (response.status === 422) {
      return response.json();
    }
    throw new Error(`HttpError: ${response.status} ${response.statusText}`);
  }
  return response.json(); // parses JSON response into native JavaScript objects
}

// Getting data
export async function getData(url = "") {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Action: "application/json",
    },
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export function concatName(name) {
  return name.split(" ").join("-");
}

export function replaceSomeSpecialChars(word) {
  return word
    .toLowerCase()
    .replace(/ä/g, "a")
    .replace(/ö/g, "o")
    .replace(/å/g, "å")
    .replace(/é/g, "e")
    .replace(/\s/g, "");
}

export function addDarkTheme(element) {
  if (element) {
    element.classList.remove("navigation-light");
    element.classList.remove("position-absolute");
    element.classList.add("position-fixed");
    element.classList.add("navigation-dark");
    element.classList.add("navigation-light-bg");
  }
}

export function addLightTheme(element) {
  if (element) {
    element.classList.remove("navigation-dark");
    element.classList.remove("position-fixed");
    element.classList.remove("navigation-light-bg");
    element.classList.add("position-absolute");
    element.classList.add("navigation-light");
  }
}

export function calculatedViewport() {
  var e = window,
    a = "inner";
  if (!("innerWidth" in window)) {
    a = "client";
    e = document.documentElement || document.body;
  }
  return { width: e[a + "Width"], height: e[a + "Height"] };
}

// Saving search data to local storage
export function saveSearchDataToStorage(name, data) {
  sessionStorage.setItem(name, JSON.stringify(data));
}

// Retrieving search data from localstorage
export function getSearchDataFromStorage(name) {
  let search_data = JSON.parse(sessionStorage.getItem(name));

  if (search_data) {
    return search_data;
  }

  return null;
}

export function zeroPadding(value) {
  return value < 10 ? `0${value}` : value;
}

// Readable data format
export function readableDateFormat(date) {
  let d = new Date(date);
  return `${zeroPadding(d.getDate())}.${zeroPadding(
    d.getMonth() + 1
  )}.${d.getFullYear()} 
  ${zeroPadding(d.getHours())}:${zeroPadding(d.getMinutes())}:${zeroPadding(
    d.getSeconds()
  )}`;
}

export function calculateLoan(
  loan_data,
  last_payment,
  months,
  reductions,
  percentage = null
) {
  let final = 0;

  let loan =
    loan_data.price -
    (loan_data.price * last_payment) / 100 +
    loan_data.base -
    reductions;
  if (percentage) {
    loan = loan_data.price - last_payment + loan_data.base - reductions;
  }

  if (loan != 0 && months != 0 && loan_data.interest != 0) {
    let interest = loan_data.interest / 100;

    let k1 = interest / 12;
    let k2 = 1 + k1;
    let k3 = Math.pow(k2, months);
    let max = k1 * k3;
    let min = k3 - 1;
    let divide = max / min;
    final = loan * divide + loan_data.handling;
  } else if (loan != 0 && months != 0 && interest == 0) {
    final = loan / months + loan_data.handling;
  } else {
    final = 0;
  }

  if (final < 0) final = 0;

  return final.toFixed(0);
}

export function setCookie(cname, cvalue, exdays) {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return true;
    }
  }
  return false;
}

export function currentDateNoTime() {
  let date = new Date();
  return `${date.getFullYear()}-${zeroPadding(
    date.getMonth() + 1
  )}-${zeroPadding(date.getDate())}`;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function breadCrumbToName(name) {
  let name_to_caps = capitalizeFirstLetter(name);
  return name_to_caps.split("-").join(" ");
}

export function removeFieldsFromObj(obj, array) {
  let n_obj = {};
  let obj_properties = Object.keys(obj)
    .filter((key) => array.indexOf(key) < 0)
    .map((key) => {
      n_obj[key] = obj[key];
    });
  return n_obj;
}

export function replaceClassName(element, current_class, new_class_name) {
  if (element.classList.contains(current_class)) {
    element.classList.remove(current_class);
  }
  element.classList.add(new_class_name);
}

export function carPropertiesToFi(key) {
  const properties = {
    base_type: "Tyyppi",
    make: "Merkki",
    model: "Malli",
    body_type: "Korimalli",
    price: "Hinta",
    monthly: "KKerä",
    fuel: "Käyttövoima",
    year: "Vuosimalli",
    mileage: "Mittarilukema",
    gear: "Vaihteisto",
    drive_type: "Vetotapa",
    engine_capacity: "Moottoritilavuus",
    seats: "Henkilömäärä",
    department_location: "Sijainti",
  };

  return properties[key];
}

// Getting search params from url
export function getSearchDataFromUrl(search_string = null) {
  const query_string = search_string ? search_string : window.location.search;
  const url_params = new URLSearchParams(query_string);
  const entries = url_params.entries();
  let data_obj = {};

  for (const entry of entries) {
    data_obj[entry[0]] = entry[1];
  }

  return data_obj;
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  const re = /[0-9]/;
  return re.test(phone);
}

export function updateDataLayer(car_id, car_price, pageType) {
  if (window.dataLayer) {
    dataLayer.push({
      item_Id: car_id,
      value: car_price,
      dynx_itemid: car_id,
      dynx_pagetype: pageType,
      dynx_totalvalue: car_price,
      event: pageType,
    });
  }
}

export function updateDataLayerEvents(event_name, car_info) {
  if (window.dataLayer) {
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: event_name,
      ecommerce: {
        currencyCode: "EUR",
        add: {
          // 'add' actionFieldObject measures.
          products: [
            {
              name: `${car_info["make"]} ${car_info["model"]}`, // Name is required.
              id: car_info["easy_ad_id"], // The ID of the car in your database, the registration number is not suitable as it is considered personally identifiable information
              price: car_info["price"],
              brand: `${car_info["make"]}`, // Car brand
              category: `${car_info["drive_type"]}`, // Drive type
              variant: `${car_info["color"]}`, // Color
              metric1: car_info["year"], //Integer, ie '2015'
              metric2: car_info["mileage"], //Integer, ie '149203'
              metric3: car_info["price"], //Decimal, ie '14900.00'
              dimension7: `${car_info["make"]}`,
              dimension8: `${car_info["model"]}`,
              dimension9: `${car_info["color"]}`,
              dimension10: `${car_info["drive_type"]}`,
              dimension11: car_info["trailer_hitch"],
              monthly_payment: car_info["monthly_payment"],
              fuel_consumption_combined: car_info["combined"],
            },
          ],
        },
      },
    });
  }
}

export function enableDisableFormFields(form, action) {
  let is_disabled = action === "disable" ? true : false;
  let inputs = form.elements;
  Array.from(inputs).forEach((input) => {
    input.disabled = is_disabled;
  });
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
